<template>
   <section class="login-content">
      <div class="container">
         <div class="row align-items-center justify-content-center height-self-center">
            <div class="col-lg-8">
               <div class="card auth-card">
                  <div class="card-body p-0">
                     <div class="d-flex align-items-center auth-content">
                        <div class="col-lg-7 align-self-center">
                           <div class="p-3">
                              <h2 class="mb-2">로그인</h2>
                              <!-- <p>Login to stay connected.</p> -->
                              <form @submit.prevent="onSubmit()">
                                 <div class="row">
                                    <!-- <div class="col-lg-12">
                                          <div class="floating-label form-group">
                                             <input class="floating-input form-control" type="email" placeholder=" " v-model="companyid">
                                             <label>Company ID</label>
                                          </div>
                                       </div>                                        -->
                                    <div class="col-lg-12">
                                       <div class="floating-label form-group">
                                          <input class="floating-input form-control" type="email" placeholder=" "
                                             v-model="email">
                                          <label>Email</label>
                                       </div>
                                    </div>
                                    <div class="col-lg-12">
                                       <div class="floating-label form-group">
                                          <input class="floating-input form-control" type="password" placeholder=" "
                                             v-model="pw">
                                          <label>Password</label>
                                       </div>
                                    </div>
                                    <!-- <div class="col-lg-6">
                                          <div class="custom-control custom-checkbox mb-3">
                                             <input type="checkbox" class="custom-control-input" id="customCheck1">
                                             <label class="custom-control-label control-label-1" for="customCheck1">Remember Me</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <router-link :to="{name: 'auth.recover-password'}" class="text-primary float-right">Forgot Password?</router-link>
                                       </div> -->
                                 </div>
                                 <button type="submit" class="btn btn-primary">로그인</button>
                                 <!-- <button v-b-modal.modal-reg class="btn btn-primary">modal</button> -->
                                 <img v-if="showspinner" style="max-width:100%;"
                                    :src="require('../../../assets/images/small/spinner.gif')">

                                 <b-modal id="modal-reg" size="lg" title="쿠플러스 구독 결제" ok-only ok-title='취소'>
                                    <b-card>
                                       <div class="terms_box" tabindex="0" id="divPrivacy" style="font-size:80%">
                                          <form @submit.prevent="submitForm">
                                             <div class="container-fluid">
                                                <b-row>
                                                   <b-col lg="6" sm="6">
                                                      <card
                                                         class=" card-block card-stretch card-height blog pricing-details"
                                                         bodyClass="border text-center rounded">
                                                         <template v-slot:body>
                                                            <div class="pricing-header">
                                                               <div class="icon-data"><i class="ri-star-line"></i>
                                                               </div>
                                                               <h2 class="mt-4 mb-4 display-5 font-weight-bolder">
                                                                  49,000<small class="font-size-14 text-muted">/
                                                                     Month</small></h2>
                                                            </div>
                                                            <h3 class="mb-3">라이트</h3>
                                                            <b-form-radio v-model="selected" name="some-radios"
                                                               value="lite" button
                                                               button-variant="outline-primary">선택</b-form-radio>
                                                            <ul class="list-unstyled mt-3 mb-0">
                                                               <li>로켓 신규등록 현황</li>
                                                               <li>경쟁사 판매량 추적 (15슬롯)</li>
                                                               <li>쿠팡 랭킹추적 (20슬롯)</li>
                                                               <li>키워드 분석</li>
                                                               <li>제트ON/신규ON/리뷰분석 기능</li>
                                                            </ul>

                                                         </template>
                                                      </card>
                                                   </b-col>
                                                   <b-col lg="6" sm="6">
                                                      <card
                                                         class=" card-block card-stretch card-height blog pricing-details"
                                                         bodyClass="border text-center rounded">
                                                         <template v-slot:body>
                                                            <div class="pricing-header">
                                                               <div class="icon-data"><i class="ri-star-line"></i><i
                                                                     class="ri-star-line"></i><i class="ri-star-line"></i>
                                                               </div>
                                                               <h2 class="mt-4 mb-4 display-5 font-weight-bolder">
                                                                  290,000<small class="font-size-14 text-muted">/
                                                                     Month</small></h2>
                                                            </div>
                                                            <h3 class="mb-3">프리미엄</h3>
                                                            <b-form-radio v-model="selected" name="some-radios"
                                                               value="premium" button
                                                               button-variant="outline-primary">선택</b-form-radio>
                                                            <ul class="list-unstyled mt-3 mb-0">
                                                               <li>로켓 신규등록 현황</li>
                                                               <li>경쟁사 판매량 추적 (50슬롯)</li>
                                                               <li>쿠팡 랭킹추적 (60슬롯)</li>
                                                               <li>키워드 분석</li>
                                                               <li>제트ON/신규ON/리뷰분석 기능</li>
                                                               <li class="mt-2">1688상품수집, 가격설정, 상세페이지 생성</li>
                                                               <li>로켓 제품 일자별 재고 및 출고현황</li>
                                                               <li>판매통계 기반 로켓 발주 필요수량 예측</li>
                                                               <li>바코드 프린트 (한글표시사항 포함)</li>
                                                               <li>로켓 발주 내역 및 세부내역 엑셀 다운로드</li>
                                                               <li>로켓 마이너스 발주 확인</li>

                                                            </ul>

                                                         </template>
                                                      </card>
                                                   </b-col>
                                                </b-row>
                                             </div>
                                             <b-row>
                                                <b-col lg="12">
                                                   <div class="p-3">
                                                      <h5>주의사항</h5>
                                                      <span>- 구독결제는 신용카드 결제로만 가능합니다.</span>
                                                      <br><span>- 신용카드 결제 진행을 위해 개인정보수집 동의 및 약관 동의는 필수입니다.</span>
                                                      <br><span>- 유효기간은 다음달 같은 날짜까지 입니다. (예시. 23.02.17 결제 시 23.03.17까지
                                                         유효기간)</span>
                                                      <br><span>- 무단 사용을 금지하기 위해 특정 IP, 기기에 대해 이용이 차단될 수 있습니다.</span>
                                                      <br><span>- 슬롯갯수는 최대 사용갯수로 한도 내에서 다른 링크로 바꿔서 사용 가능합니다.</span>
                                                      <br><span>- 추가 문의사항은 채널톡으로 문의바랍니다.</span>
                                                      <br><span>- 쿠플러스 채널 : http://pf.kakao.com/_xhDjxeb</span>
                                                   </div>
                                                </b-col>
                                                <b-col lg="5">
                                                   <div class="mt-3 card p-3 ">
                                                      <div
                                                         class="d-flex justify-content-start align-items-center text-center">
                                                         <span class="w-25">카드번호</span>
                                                         <b-form-input class="w-75 ml-2" v-model="cardNumber" type="number"
                                                            @input="validateCardNumber"
                                                            @change="confirmcheck"></b-form-input>

                                                      </div>
                                                      <div class="d-flex justify-content-start align-items-center text-center mt-2">
                                                         <span class="w-25">유효기간</span>
                                                         <b-form-input class="w-25 ml-2" v-model="cardExpirationMonth"
                                                            type="number" placeholder="MM" max="12"
                                                            @input="validateExpiryDate"
                                                            @change="confirmcheck"></b-form-input>
                                                         <b-form-input class="w-25 ml-2" v-model="cardExpirationYear"
                                                            type="number" placeholder="YY" max="99"
                                                            @input="validateExpiryDate"
                                                            @change="confirmcheck"></b-form-input>
                                                      </div>

                                                      <div class="d-flex justify-content-start align-items-center text-center mt-2" v-if="법인카드 == false">
                                                         <span class="w-25">생년월일</span>
                                                         <b-form-input class="w-75 ml-2" v-model="customerIdentityNumber"
                                                            type="number" @input="validatecustomernumber" placeholder="YYMMDD"
                                                            @change="confirmcheck"></b-form-input>
                                                      </div>

                                                      <div class="d-flex justify-content-start align-items-center text-center mt-2" v-if="법인카드 == true">
                                                         <span class="w-25">사업자<br>번호</span>
                                                         <b-form-input class="w-75 ml-2" v-model="customerIdentityNumber"
                                                            type="number" placeholder="사업자번호 10자리"
                                                            @input="validatecustomernumber"
                                                            @change="confirmcheck"></b-form-input>
                                                      </div>

                                                      <div class="d-flex justify-content-start align-items-center text-center mt-2">
                                                         <div class="custom-control custom-switch custom-control-inline ml-1">
                                                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="법인카드" @change="checkcard">
                                                            <label class="custom-control-label" for="customSwitch1"><span>법인카드</span></label>
                                                         </div>
                                                      </div>

                                                   </div>
                                                   <div>
                                                      <div v-if="cardNumberError" class="error-message">{{ cardNumberError
                                                      }}</div>
                                                      <div v-if="expiryDateError" class="error-message">{{ expiryDateError
                                                      }}</div>
                                                      <div v-if="birthdateError" class="error-message">{{ birthdateError }}
                                                      </div>
                                                      <!-- <div v-if="cardPasswordError" class="error-message">{{
                                                         cardPasswordError }}</div> -->
                                                   </div>

                                                </b-col>
                                                <b-col lg="7">
                                                   <div class="mt-1 p-3">
                                                      <div
                                                         class="d-flex justify-content-start align-items-center text-center">
                                                         <b-form-checkbox v-model="allchecked" @change="allcheck">
                                                            <b>모든 약관에 동의합니다.</b>
                                                         </b-form-checkbox>
                                                      </div>
                                                      <hr>
                                                      <div
                                                         class="d-flex justify-content-start align-items-center text-center">
                                                         <b-form-checkbox v-model="check1" @change="confirmcheck">
                                                            결제 조건을 확인하였으며, 결제 진행에 동의합니다. <b> (필수) </b>
                                                         </b-form-checkbox>
                                                      </div>
                                                      <div
                                                         class="d-flex justify-content-start align-items-center text-center">
                                                         <b-form-checkbox v-model="check2" @change="confirmcheck">
                                                            개인정보수집/이용에 동의합니다. <b>(필수)</b>&nbsp;&nbsp;<u
                                                               v-b-modal.privacy>보기</u>
                                                         </b-form-checkbox>
                                                      </div>
                                                      <div
                                                         class="d-flex justify-content-start align-items-center text-center">
                                                         <b-form-checkbox v-model="check3" @change="confirmcheck">
                                                            결제대행 서비스 이용약관에 동의합니다. <b>(필수)</b>&nbsp;&nbsp;<u
                                                               v-b-modal.terms>보기</u>
                                                         </b-form-checkbox>
                                                      </div>
                                                      <div
                                                         class="d-flex justify-content-start align-items-center text-center">
                                                         <b-form-checkbox v-model="check4" @change="confirmcheck">
                                                            쿠플러스 서비스 이용약관에 동의합니다. <b>(필수)</b>&nbsp;&nbsp;<u
                                                               v-b-modal.service>보기</u>
                                                         </b-form-checkbox>
                                                      </div>
                                                      <div class="d-flex justify-content-end">
                                                         <!-- <button type="button" :class="classapproval" @click="payment">결제하기</button> -->
                                                         <button type="button" :class="classapproval" v-b-modal.gopayment @click="makenow">결제하기</button>
                                                      </div>

                                                      <!-- <b-modal id="gopayment" size="lg" ok-only>
                                                         <div class="text-center mt-4">
                                                            <p>PG사 계약 및 카드사 심사 중으로 구독결제는 계약완료 후 활성화될 예정입니다.</p>
                                                            <p>지금 바로 이용이 필요하신 경우 아래 계좌로 입금해주시면 순차적으로 승인처리하도록 하겠습니다.</p>
                                                            <p>기타 문의사항은 채널톡으로 남겨주세요. <a href="http://pf.kakao.com/_xhDjxeb" target="_blank">http://pf.kakao.com/_xhDjxeb'</a></p>

                                                            <p class="mt-3">국민은행 850101-00-098874 (주)루비데이터랩</p>
                                                            <p>라이트버전:49,000원, 프리미엄버전:290,000원</p>
                                                            <p>****** 입금자명과 가입자명이 일치해야 빠르게 승인이 가능합니다.******</p>
                                                         </div>
                                                      </b-modal> -->

                                                      <b-modal id="gopayment" size="lg" ok-title="결제하기" cancel-title="취소" @ok="payment">
                                                         <div class="text-center mt-4">
                                                            <h5>매월 {{ now }}일에 자동으로 결제됩니다. 계속하시겠습니까?</h5>
                                                            <p><small>구독 취소는 로그인 후 우측상단 사용자 클릭 > 구독관리에서 확인 가능합니다.</small></p>
                                                         </div>
                                                      </b-modal>


                                                   </div>

                                                </b-col>

                                             </b-row>


                                          </form>
                                       </div>
                                    </b-card>
                                 </b-modal>

                                 <b-modal id="privacy" size="lg" ok-title="동의하기" cancel-title="취소" @ok="checkprivacy">
                                    <div class="_2mP0n css-1vgo9k0">
                                       <div class="css-ign6bq e1rmugrt4">
                                          <h4 class="css-a42nca e1rmugrt3 text-center">개인정보수집/이용 동의</h4>
                                          <div class="css-9tq7o4 e1rmugrt2">
                                             <div class="css-0 eud7hl51">
                                                <div class="css-0 e8tfira1">
                                                   <div class="css-axl7p5 e8tfira0">
                                                      <p><br></p>
                                                      <p dir="ltr"
                                                         style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
                                                         <span
                                                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">
                                                            (주)루비데이터랩은 원활한 서비스제공을 위해 최소한의 범위내에서 아래와 같이 개인정보를 수집, 이용합니다.
                                                         </span>
                                                      </p>
                                                      <p><br></p>
                                                      <p dir="ltr"
                                                         style="line-height:1.295;text-align: justify;margin-top:0pt;margin-bottom:8pt;">
                                                         <span
                                                            style="font-size:12pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.
                                                            개인정보의 수집·이용 목적</span>
                                                      </p>
                                                      <p dir="ltr"
                                                         style="line-height:1.295;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:18pt;">
                                                         <span
                                                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">쿠플러스
                                                            사용을 위해 전자 결제 사용 시 아래 목적으로 개인정보 수집 및 이용됩니다.</span>
                                                      </p>
                                                      <ul
                                                         style="margin-top:0;margin-bottom:0;padding-inline-start:48px;text-align: left;">
                                                         <li aria-level="1" dir="ltr"
                                                            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;">
                                                            <p dir="ltr"
                                                               style="line-height:1.295;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
                                                               <span
                                                                  style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">결제수단(신용카드
                                                                  등)을 이용한 쿠플러스 웹 서비스 제공</span>
                                                            </p>
                                                         </li>
                                                         <li aria-level="1" dir="ltr"
                                                            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;">
                                                            <p dir="ltr"
                                                               style="line-height:1.295;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
                                                               <span
                                                                  style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">전자결제
                                                                  결과 조회 및 통보</span>
                                                            </p>
                                                         </li>
                                                         <li aria-level="1" dir="ltr"
                                                            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;">
                                                            <p dir="ltr"
                                                               style="line-height:1.295;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
                                                               <span
                                                                  style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">결제한
                                                                  거래의 취소 또는 환불</span>
                                                            </p>
                                                         </li>
                                                         <li aria-level="1" dir="ltr"
                                                            style="list-style-type:disc;font-size:13pt;font-family:'Helvetica Neue',sans-serif;color:#4e5968;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;">
                                                            <p dir="ltr"
                                                               style="line-height:1.295;background-color:#ffffff;margin-top:0pt;margin-bottom:30pt;">
                                                               <span
                                                                  style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">전자결제
                                                                  이력 관리 및 민원 대응</span>
                                                            </p>
                                                         </li>
                                                      </ul>
                                                      <p dir="ltr"
                                                         style="line-height:1.295;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 18pt 0pt;">
                                                         <span
                                                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">※&nbsp;</span><span
                                                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">개인정보
                                                            제공에 동의하지 않으실 수 있으며, 동의하지 않으실 경우 서비스 이용이 제한될 수 있습니다.</span><span
                                                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;수집한
                                                            개인정보는 위의 목적으로 처리하며, 동의받은 목적 이외의 용도로 처리하지 않습니다.&nbsp;</span>
                                                      </p>
                                                      <p dir="ltr"
                                                         style="line-height:1.295;text-align: justify;margin-top:0pt;margin-bottom:8pt;">
                                                         <span
                                                            style="font-size:12pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.
                                                            수집·이용하는 개인정보 항목</span>
                                                      </p>
                                                      <p dir="ltr"
                                                         style="line-height:1.295;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:18pt;">
                                                         <span
                                                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">쿠플러스
                                                            사용 목적의 전자 결제 시 아래 개인정보 항목이 수집됩니다.<br>쿠플러스에 회원가입 시 입력한 회원 정보가
                                                            사용됩니다.</span>
                                                      </p>

                                                      <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                                         <li aria-level="1" dir="ltr"
                                                            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;">
                                                            <p dir="ltr"
                                                               style="line-height:1.295;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
                                                               <span
                                                                  style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">사용고객
                                                                  정보</span>
                                                            </p>
                                                         </li>
                                                         <li aria-level="1" dir="ltr"
                                                            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;">
                                                            <p dir="ltr"
                                                               style="line-height:1.295;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
                                                               <span
                                                                  style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">신용카드
                                                                  정보</span>
                                                            </p>
                                                         </li>
                                                      </ul>

                                                      <p><br></p>
                                                      <p dir="ltr"
                                                         style="line-height:1.295;text-align: justify;margin-top:0pt;margin-bottom:8pt;">
                                                         <span
                                                            style="font-size:12pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.
                                                            개인정보의 보유 및 이용기간</span>
                                                      </p>
                                                      <p dir="ltr"
                                                         style="line-height:1.295;text-align: justify;margin-top:0pt;margin-bottom:8pt;">
                                                         <span
                                                            style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">쿠플러스
                                                            계정 탈퇴시까지</span><span
                                                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,</span><span
                                                            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;단,
                                                            전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령의 규정에 따라 거래 관계 확인을 위해 개인정보를 일정기간
                                                            보유할 수 있습니다</span><span
                                                            style="font-size:10.5pt;font-family:'Malgun Gothic';color:#757575;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
                                                      </p>
                                                      <p><br></p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="css-1azakc e1rmugrt1"></div>
                                       </div>
                                    </div>
                                 </b-modal>
                                 <b-modal id="terms" size="lg" ok-title="동의하기" cancel-title="취소" @ok="checkterms">

                                    <div>
                                       <div id="___gatsby">
                                          <div class="d-flex justify-content-center">
                                             <b-form-group>
                                                <b-form-radio-group v-model="selectedradio" :options="options"
                                                   button-variant="outline-primary" size="lg" buttons
                                                   @change="contentchange"></b-form-radio-group>
                                             </b-form-group>
                                          </div>
                                          <div style="outline:none" tabindex="-1" id="gatsby-focus-wrapper">
                                             <div class="p-container p-container--default css-1guwpmc">
                                                <div class="p-container__inner p-5">
                                                   <header class="css-12layeo">
                                                      <img width="150"
                                                         src="https://static.toss.im/icons/png/4x/logo-payments-short-blue.png">
                                                   </header>
                                                   <article v-if="showradio1" style="font-size:80%"
                                                      class="mt-3 p-post text--left css-asw555-visuallyHidden">
                                                      <h3>전자금융거래 기본약관(이용자용)</h3>
                                                      <h4>제1조 (목적)</h4>
                                                      <p>이 약관은 전자지급결제대행서비스 및 결제대금예치서비스를 제공하는 토스페이먼츠 주식회사(이하 '회사'라 합니다)와 이용자
                                                         사이의 전자금융거래에 관한 기본적인 사항을 정함으로써 전자금융거래의 안정성과 신뢰성을 확보함에 그 목적이 있습니다.
                                                      </p>
                                                      <h4>제2조 (용어의 정의)</h4>
                                                      <p>이 약관에서 정하는 용어의 정의는 다음과 같습니다.</p>
                                                      <p>① 전자금융거래'라 함은 회사가 전자적 장치를 통하여 전자지급결제대행서비스 및 결제대금예치서비스(이하 '전자금융거래
                                                         서비스'라고 합니다)를 제공하고, 이용자가 회사의 구성원과 직접 대면하거나 의사소통을 하지 아니하고 전산화된 방식으로
                                                         이를 이용하는 거래를 말합니다.</p>
                                                      <p>② '전자지급결제대행 서비스'라 함은 전자적 방법으로 재화의 구입 또는 용역의 이용에 있어서 지급결제정보를 송신하거나
                                                         수신하는 것 또는 그 대가의 정산을 대행하거나 매개하는 서비스를 말합니다.</p>
                                                      <p>③ ‘결제대금예치서비스'라 함은 이용자가 재화의 구입 또는 용역의 이용에 있어서 그 대가(이하 '결제대금'이라 한다)의
                                                         전부 또는 일부를 재화 또는 용역(이하 '재화 등'이라 합니다)을 공급받기 전에 미리 지급하는 경우, 회사가 이용자의
                                                         물품수령 또는 서비스 이용 확인 시점까지 결제대금을 예치하는 서비스를 말합니다.</p>
                                                      <p>④ '이용자'라 함은 이 약관에 동의하고 회사가 제공하는 전자지급결제대행 서비스를 이용하는 자를 말합니다.</p>
                                                      <p>⑤ '접근매체'라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여
                                                         사용되는 수단 또는 정보로서 전자식 카드 및 이에 준하는 전자적 정보(신용카드번호를 포함한다), '전자서명법'상의
                                                         인증서, 금융기관 또는 전자금융업자에 등록된 이용자번호, 이용자의 생체정보, 이상의 수단이나 정보를 사용하는데 필요한
                                                         비밀번호 등 전자금융거래법 제2조 제10호에서 정하고 있는 것을 말합니다.</p>
                                                      <p>⑥ '거래지시'라 함은 이용자가 전자금융거래계약에 따라 금융기관 또는 전자금융업자에게 전자금융거래의 처리를 지시하는
                                                         것을 말합니다.</p>
                                                      <p>⑦ '오류'라 함은 이용자의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 이용자의 거래지시에 따라 이행되지
                                                         아니한 경우를 말합니다.</p>
                                                      <h4>제3조 (약관의 명시 및 변경)</h4>
                                                      <p>① 회사는 이용자가 전자지급결제대행 서비스를 이용하기 전에 이 약관을 게시하고 이용자가 이 약관의 중요한 내용을 확인할
                                                         수 있도록 합니다.</p>
                                                      <p>② 회사는 이용자의 요청이 있는 경우 서면제공 방식 또는 전자문서의 전송방식에 의하여 본 약관의 사본을 이용자에게
                                                         교부합니다.</p>
                                                      <p>③ 회사가 약관을 변경하는 때에는 그 시행일 1월 전에 변경되는 약관을 지급결제정보 입력화면 및 회사의 홈페이지에
                                                         게시함으로써 이용자에게 공지합니다.</p>
                                                      <p>④ 이용자는 제3항의 규정에 따른 약관의 변경내용이 게시되거나 통지된 후부터 변경되는 약관의 시행일 전의 영업일까지
                                                         전자금융거래의 계약을 해지할 수 있습니다. 전단의 기간 안에 이용자가 약관의 변경내용에 대하여 이의를 제기하지 아니하는
                                                         경우에는 약관의 변경을 승인한 것으로 봅니다.</p>
                                                      <h4>제4조 (전자지급결제대행서비스의 종류)</h4>
                                                      <p>회사가 제공하는 전자지급결제대행서비스는 지급결제수단에 따라 다음과 같이 구별됩니다.</p>
                                                      <p>① 신용카드결제대행서비스: 이용자가 결제대금의 지급을 위하여 제공한 지급결제수단이 신용카드인 경우로서, 회사가
                                                         전자결제시스템을 통한 신용카드 지불정보의 송,수신 및 결제대금의 정산을 대행하거나 매개하는 서비스를 말합니다.</p>
                                                      <p>② 계좌이체대행서비스: 이용자가 결제대금을 회사의 전자결제시스템을 통하여 금융기관에 등록한 자신의 계좌에서 출금하여
                                                         원하는 계좌로 이체할 수 있는 실시간 송금 서비스를 말합니다.</p>
                                                      <p>③ 가상계좌서비스: 이용자가 결제대금을 현금으로 결제하고자 경우 회사의 전자결제시스템을 통하여 자동으로 이용자만의
                                                         고유한 일회용 계좌의 발급을 통하여 결제대금의 지급이 이루어지는 서비스를 말합니다.</p>
                                                      <p>④ 기타: 회사가 제공하는 서비스로서 지급결제수단의 종류에 따라 '휴대폰 결제대행서비스',
                                                         'KT전화(ARS,폰빌)결제대행서비스', '상품권결제대행서비스'등이 있습니다.</p>
                                                      <h4>제5조 (결제대금예치서비스의 내용)</h4>
                                                      <p>① 이용자(이용자의 동의가 있는 경우에는 재화 등을 공급받을 자를 포함합니다. 이하 본조에서 같습니다)는 재화 등을
                                                         공급받은 사실을 재화 등을 공급받은 날부터 3영업일 이내에 회사에 통보하여야 합니다.</p>
                                                      <p>② 회사는 이용자로부터 재화 등을 공급받은 사실을 통보 받은 후 회사와 통신판매업자간 사이에서 정한 기일 내에
                                                         통신판매업자에게 결제대금을 지급합니다.</p>
                                                      <p>③ 회사는 이용자가 재화 등을 공급받은 날부터 3영업일이 지나도록 정당한 사유의 제시 없이 그 공급받은 사실을 회사에
                                                         통보하지 아니하는 경우에는 이용자의 동의 없이 통신판매업자에게 결제대금을 지급할 수 있습니다.</p>
                                                      <p>④ 회사는 통신판매업자에게 결제대금을 지급하기 전에 이용자에게 결제대금을 환급받을 사유가 발생한 경우에는 그 결제대금을
                                                         소비자에게 환급합니다.</p>
                                                      <p>⑤ 회사는 이용자와의 결제대금예치서비스 이용과 관련된 구체적인 권리,의무를 정하기 위하여 본 약관과는 별도로
                                                         결제대금예치서비스이용약관을 제정할 수 있습니다.</p>
                                                      <h4>제6조 (접근매체의 선정, 관리 등)</h4>
                                                      <p>① 회사는 전자지급결제대행 서비스 제공 시 접근매체를 선정하여 이용자의 신원, 권한 및 거래지시의 내용 등을 확인할 수
                                                         있습니다.</p>
                                                      <p>② 이용자는 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도 또는 담보 목적으로 제공할 수 없습니다.</p>
                                                      <p>③ 이용자는 자신의 접근매체를 제3자에게 누설 또는 노출하거나 방치하여서는 안되며, 접근매체의 도용이나 위조 또는
                                                         변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.</p>
                                                      <p>④ 회사는 이용자로부터 접근매체의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 접근매체를 사용함으로
                                                         인하여 이용자에게 발생한 손해를 배상할 책임이 있습니다.</p>
                                                      <h4>제7조 (회사의 책임)</h4>
                                                      <p>① 접근매체의 위조나 변조로 발생한 사고로 인하여 이용자에게 발생한 손해에 대하여 배상책임이 있습니다. 다만, 이용자가
                                                         접근매체를 제3자에게 대여하거나 그 사용을 위임한 경우 또는 양도나 담보의 목적으로 제공한 경우, 회사가 보안강화를
                                                         위하여 전자금융거래 시 요구하는 추가적인 보안조치를 이용자가 정당한 사유 없이 거부하여 정보통신망에 침입하여 거짓이나
                                                         그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 사고가 발생한 경우, 이용자가 제6조 제2항에 위반하거나 제3자가
                                                         권한 없이 이용자의 접근매체를 이용하여 전자금융거래를 할 수 있음을 알았거나 알 수 있었음에도 불구하고 이용자가 자신의
                                                         접근매체를 누설 또는 노출하거나 방치한 경우 그 책임의 전부 또는 일부를 이용자가 부담하게 할 수 있습니다.</p>
                                                      <p>② 회사는 계약체결 또는 거래지시의 전자적 전송이나 처리과정에서 발생한 사고로 인하여 이용자에게 손해가 발생한 경우에는
                                                         그 손해를 배상할 책임이 있습니다. 다만, 본조 제1항 단서에 해당하거나 법인('중소기업기본법' 제2조 제2항에 의한
                                                         소기업을 제외한다)인 이용자에게 손해가 발생한 경우로서 회사가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히
                                                         준수하는 등 합리적으로 요구되는 충분한 주의의무를 다한 경우 그 책임의 전부 또는 일부를 이용자가 부담하게 할 수
                                                         있습니다.</p>
                                                      <p>③ 회사는 전자금융거래를 위한 전자적 장치 또는 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 제2조제1항제1호에
                                                         따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한 사고로 인하여 이용자에게
                                                         그 손해가 발생한 경우에는 그 손해를 배상할 책임이 있습니다.</p>
                                                      <h4>제8조 (거래내용의 확인)</h4>
                                                      <p>① 회사는 이용자와 미리 약정한 전자적 방법을 통하여 이용자의 거래내용(이용자의 '오류정정 요구사실 및 처리결과에 관한
                                                         사항'을 포함합니다)을 확인할 수 있도록 하며, 이용자의 요청이 있는 경우에는 요청을 받은 날로부터 2주 이내에
                                                         전자적양식, 모사전송, 서면 등의 방법으로 거래내용에 관한 서면을 교부합니다. 전자적 장치의 운영장애, 그 밖의 사유로
                                                         거래내용을 확인하게 할 수 없는 때에는 인터넷 등을 이용하여 즉시 그 사유를 알리고, 그 사유가 종료된 때부터 이용자가
                                                         거래내용을 확인할 수 있도록 하여야 합니다.</p>
                                                      <p>② 회사가 이용자에게 제공하는 거래내용 중 거래계좌의 명칭 또는 번호, 거래의 종류 및 금액, 거래상대방을 나타내는
                                                         정보, 거래일자, 전자적 장치의 종류 및 전자적 장치를 식별할 수 있는 정보와 해당 전자금융거래와 관련한 전자적 장치의
                                                         접속기록은 5년간, 건당 거래금액이 1만원 이하인 소액 전자금융거래에 관한 기록, 전자지급수단 이용시 거래승인에 관한
                                                         기록, 이용자의 오류정정 요구사실 및 처리결과에 관한 사항은 1년간의 기간을 대상으로 하되, 회사가 가맹점에 대한
                                                         전자지급결제대행 서비스 제공의 대가로 수취한 수수료에 관한 사항은 제공하는 거래내용에서 제외됩니다.</p>
                                                      <p>③ 이용자가 본조 제1항에서 정한 서면교부를 요청하고자 할 경우 다음의 주소 및 전화번호로 요청할 수 있습니다.</p>
                                                      <ul>
                                                         <li>주소 : 서울특별시 강남구 테헤란로 131 한국지식재산센터 15층 토스페이먼츠 주식회사</li>
                                                         <li>이메일 주소 : support@tosspayments.com</li>
                                                         <li>전화번호 : 1544-7772</li>
                                                      </ul>
                                                      <h4>제9조 (오류의 정정 등)</h4>
                                                      <p>① 이용자는 전자지급결제대행 서비스를 이용함에 있어 오류가 있음을 안 때에는 회사에 대하여 그 정정을 요구할 수
                                                         있습니다.</p>
                                                      <p>② 회사는 전항의 규정에 따른 오류의 정정요구를 받은 때에는 이를 즉시 조사하여 처리한 후 정정요구를 받은 날부터 2주
                                                         이내에 그 결과를 이용자에게 알려 드립니다.</p>
                                                      <p>③ 이용자는 다음의 주소 및 전화번호로 본 조항상의 정정 요구를 할 수 있습니다.</p>
                                                      <ul>
                                                         <li>주소 : 서울특별시 강남구 테헤란로 131 한국지식재산센터 15층 토스페이먼츠 주식회사</li>
                                                         <li>이메일 주소 : support@tosspayments.com</li>
                                                         <li>전화번호: 1544 - 7772</li>
                                                      </ul>
                                                      <p>④ 회사는 스스로 전자금융거래에 오류가 있음을 안 때에는 이를 즉시 조사하여 처리한 후 오류가 있음을 안 날부터 2주
                                                         이내에 오류의 원인과 처리 결과를 이용자에게 알려 드립니다.</p>
                                                      <h4>제10조 (전자지급결제대행 서비스 이용 기록의 생성 및 보존)</h4>
                                                      <p>① 회사는 이용자가 전자지급결제대행 서비스 이용거래의 내용을 추적, 검색하거나 그 내용에 오류가 발생한 경우에 이를
                                                         확인하거나 정정할 수 있는 기록을 생성하여 보존합니다.</p>
                                                      <p>② 전항의 규정에 따라 회사가 보존하여야 하는 기록의 종류 및 보존방법은 제8조 제2항에서 정한 바에 따릅니다.</p>
                                                      <h4>제11조 (거래지시의 철회 제한)</h4>
                                                      <p>① 이용자가 전자지급거래를 한 경우, 지급의 효력은 ‘전자금융거래법’ 제13조 각호의 규정에 따릅니다.</p>
                                                      <p>② 이용자는 ‘전자금융거래법’ 제13조 각호의 규정에 따라 지급의 효력이 발생하기전까지 거래지시를 철회할 수 있습니다.
                                                         단, 금융기관, 이동통신사 등의 규정에 의거 거래지시의 철회가 제한될 수 있습니다.</p>
                                                      <p>③ 전자지급수단별 거래지시의 철회 방법 및 제한 등은 다음 각호와 같습니다.</p>
                                                      <ol>
                                                         <li>신용카드결제, 계좌이체결제, 가상계좌결제 : 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령에서 정한
                                                            바에 따라 재화의 구입 또는 용역의 이용 거래에서의 청약의 철회 등을 한 경우에 한하여 철회가 가능합니다.
                                                         </li>
                                                         <li>휴대폰결제, KT전화(ARS,폰빌)결제: 전 가호의 방법에 따른 청약철회 요청건 중 이동통신사의 규정에 의거
                                                            결제일 해당월 말일까지 발생한 거래건에 한하여 철회가 가능합니다.</li>
                                                         <li>상품권결제: 전 가호의 방법에 따른 청약철회 요청 건 중 시스템 장애 등으로 정상적인 서비스를 이용하지 못한
                                                            경우에 한하여 결제일로부터 10일 이내 청약 철회가 가능합니다.</li>
                                                      </ol>
                                                      <h4>제12조 (추심이체의 출금 동의 및 철회)</h4>
                                                      <p>① 회사는 이용자의 요청이 있는 경우 이용자의 계좌가 개설되어 있는 금융회사 등이 추심이체를 실행할 수 있도록 금융회사
                                                         등을 대신하여 전자금융거래법령 등 관련 법령에 따른 방법으로 출금에 대한 동의를 진행합니다.</p>
                                                      <p>② 회사는 전 항에 따른 이용자의 동의 사항을 추심 이체를 실행하는 해당 금융회사 등에 제출합니다.</p>
                                                      <p>③ 이용자는 이용자의 계좌의 원장에 출금기록이 끝나기 전까지 회사 또는 해당 금융회사 등에 제1항의 규정에 따른 동의의
                                                         철회를 요청할 수 있습니다.</p>
                                                      <p>④ 전 항에도 불구하고 회사 또는 금융회사 등은 대량으로 처리하는 거래 또는 예약에 따른 거래 등의 경우에는 미리
                                                         이용자와 정한 약정에 따라 동의의 철회시기를 달리 정할 수 있습니다.</p>
                                                      <p>⑤ 이용자가 제3항에 따라 출금 동의 철회를 요청한 경우, 이용자는 출금 동의 철회 요청 이후 발생한 출금에 대해서
                                                         제14조 제1항의 담당자에게 이의를 제기할 수 있습니다. 다만, 본 조항은 동의 철회요청 이전에 발생한 출금에 대해서는
                                                         적용되지 않습니다.</p>
                                                      <h4>제13조 (전자금융거래정보의 제공금지)</h4>
                                                      <p>회사는 전자지급결제대행서비스 및 결제대금예치서비스를 제공함에 있어서 취득한 이용자의 인적사항, 이용자의 계좌, 접근매체
                                                         및 전자금융거래의 내용과 실적에 관한 정보 또는 자료를 이용자의 동의를 얻지 아니하고 제3자에게 제공,누설하거나 업무상
                                                         목적 외에 사용하지 아니합니다. 단, ‘금융실명 거래 및 비밀 보장에 관한 법률’ 제4조 제1항 단서의 규정에 따른
                                                         경우 그 밖의 다른 법률에서 정하는 바에 따른 경우에는 그러하지 아니합니다.</p>
                                                      <h4>제14조 (분쟁처리 및 분쟁조정)</h4>
                                                      <p>① 이용자는 다음의 분쟁처리 책임자 및 담당자에 대하여 전자지급결제대행 서비스 및 결제대금예치서비스 이용과 관련한 의견
                                                         및 불만의 제기, 손해배상의 청구 등의 분쟁처리를 요구할 수 있습니다.</p>
                                                      <ul>
                                                         <li>담당자 : 토스페이먼츠 RM(리스크)팀</li>
                                                         <li>연락처 : 전화번호 1544-7772, 팩스 02-6919-2354</li>
                                                         <li>이메일 : rm@tosspayments.com</li>
                                                      </ul>
                                                      <p>② 이용자가 회사에 대하여 분쟁처리를 신청한 경우에는 회사는 15일 이내에 이에 대한 조사 또는 처리 결과를 이용자에게
                                                         안내합니다.</p>
                                                      <p>③ 이용자는 '금융위원회의 설치 등에 관한 법률' 제51조의 규정에 따른 금융감독원의 금융분쟁조정위원회나
                                                         '소비자기본법' 제35조 제1항의 규정에 따른 소비자원에 회사의 전자지급결제대행서비스 및 결제대금예치서비스의 이용과
                                                         관련한 분쟁조정을 신청할 수 있습니다.</p>
                                                      <h4>제15조 (회사의 안정성 확보 의무)</h4>
                                                      <p>회사는 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의 종류별로 전자적 전송이나 처리를 위한 인력,
                                                         시설, 전자적 장치 등의 정보기술부문 및 전자금융업무에 관하여 금융위원회가 정하는 기준을 준수합니다.</p>
                                                      <h4>제16조 (이용시간)</h4>
                                                      <p>① 회사는 이용자에게 연중무휴 1일 24시간 전자금융거래 서비스를 제공함을 원칙으로 합니다. 단, 금융기관 기타
                                                         결제수단 발행업자의 사정에 따라 변경될 수 있습니다.</p>
                                                      <p>② 회사는 정보통신설비의 보수, 점검 기타 기술상의 필요나 금융기관 기타 결제수단 발행업자의 사정에 의하여 서비스
                                                         중단이 불가피한 경우, 서비스 중단 3일 전까지 게시가능한 전자적 수단을 통하여 서비스 중단 사실을 게시한 후 서비스를
                                                         일시 중단할 수 있습니다. 다만, 시스템 장애보국, 긴급한 프로그램 보수, 외부요인 등 불가피한 경우에는 사전 게시없이
                                                         서비스를 중단할 수 있습니다.</p>
                                                      <h4>제17조 (약관외 준칙 및 관할)</h4>
                                                      <p>① 이 약관에서 정하지 아니한 사항에 대하여는 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률, 통신판매에
                                                         관한 법률, 여신전문금융업법 등 소비자보호 관련 법령에서 정한 바에 따릅니다.</p>
                                                      <p>② 회사와 이용자간에 발생한 분쟁에 관한 관할은 민사소송법에서 정한 바에 따릅니다.</p>
                                                   </article>
                                                   <article v-if="showradio2" style="font-size:80%"
                                                      class="mt-3 p-post text--left css-asw555-visuallyHidden">
                                                      <h3>개인정보 수집이용 동의</h3>
                                                      <p>토스페이먼츠(이하 회사라 함)는 "신용정보의 이용 및 보호에 관한 법률", "전자금융거래법", "전자상거래 등에서의
                                                         소비자보호에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 및 "개인정보 보호법" 등 관련 법령을
                                                         준수하여, 이용자 권익 보호에 최선을 다하고 있습니다.</p>
                                                      <h4>1. 개인정보의 수집·이용 목적</h4>
                                                      <p>가. 전자금융거래 서비스 제공: 전자결제지급대행, 자체 간편결제 및 오픈뱅킹 등</p>
                                                      <ul>
                                                         <li>결제수단(신용카드 등)을 이용한 전자결제 서비스 제공</li>
                                                         <li>전자결제 결과 조회 및 통보</li>
                                                         <li>세금계산서 및 현금영수증 발행</li>
                                                         <li>결제한 거래의 취소 또는 환불, 상품 배송 등 전자상거래 관련 서비스 제공</li>
                                                      </ul>
                                                      <p>나. 서비스 이용자 관리 및 민원대응</p>
                                                      <ul>
                                                         <li>이용자 본인확인, 개인식별, 공지사항의 전달, 고객 불만 및 민원처리 등</li>
                                                         <li>금융사고 조사, 분쟁해결, 민원처리, 법령상 의무이행</li>
                                                         <li>새로운 정보와 고지사항의 안내</li>
                                                      </ul>
                                                      <p>※ 회사는 개인정보를 위의 목적으로 처리하며, 동의받은 목적 이외의 용도로 처리하지 않습니다.</p>
                                                      <h4>2. 수집·이용하는 개인정보 항목</h4>
                                                      <p>가. 전자결제 시 수집되는 항목</p>
                                                      <ul>
                                                         <li>구매자명 또는 구매자ID, 결제금액</li>
                                                      </ul>
                                                      <p>나. 결제수단별 수집되는 항목</p>
                                                      <table>
                                                         <caption>결제수단별 수집되는 항목</caption>
                                                         <thead>
                                                            <tr>
                                                               <th><strong><em>결제수단</em></strong></th>
                                                               <th><strong><em>개인정보 항목</em></strong></th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            <tr>
                                                               <td>신용카드</td>
                                                               <td>카드번호, 카드 유효기간, 카드 비밀번호, 카드 CVC, 생년월일 등 (수기결제 시 수집항목을
                                                                  구분하여 진행함)</td>
                                                            </tr>
                                                            <tr>
                                                               <td>가상계좌</td>
                                                               <td>은행, 가상계좌번호, 입금자명</td>
                                                            </tr>
                                                            <tr>
                                                               <td>계좌이체</td>
                                                               <td>은행, 출금계좌번호, 비밀번호, 주민등록번호(또는 사업자등록번호), 예금주</td>
                                                            </tr>
                                                            <tr>
                                                               <td>휴대폰</td>
                                                               <td>통신사, 휴대폰번호, 주민등록번호 앞 7자리(생년월일, 성별)</td>
                                                            </tr>
                                                            <tr>
                                                               <td>상품권</td>
                                                               <td>상품권 발행사 아이디, 비밀번호’ 또는 상품권 번호</td>
                                                            </tr>
                                                            <tr>
                                                               <td>현금영수증 발행</td>
                                                               <td>‘주민등록번호, 휴대폰번호, 현금영수증번호, 기타 카드번호’ 중 현금영수증 발급정보<br>[참고]
                                                                  가상계좌, 계좌이체 시 현금영수증 발행 가능</td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                      <p>다. 전자결제 내역 발신을 위한 정보</p>
                                                      <ul>
                                                         <li>휴대폰번호, 이메일</li>
                                                      </ul>
                                                      <p>라. 자체 간편결제 이용자 정보</p>
                                                      <ul>
                                                         <li>CI, 휴대폰번호, 통신사, 생년월일, 성별, 이름</li>
                                                      </ul>
                                                      <h4>3. 개인정보의 보유 및 이용기간</h4>
                                                      <p>원칙적으로 개인정보의 수집·이용 목적이 달성되면 지체 없이 파기합니다. 단, 관련 법령의 규정 또는 회사 내부 방침에
                                                         의하여 보존할 필요가 있는 경우 아래에서 정한 기간 동안 개인정보를 보유합니다.</p>
                                                      <table>
                                                         <caption>개인정보의 보유기간</caption>
                                                         <thead>
                                                            <tr>
                                                               <th><strong><em>구분</em></strong></th>
                                                               <th><strong><em>보존 근거</em></strong></th>
                                                               <th><strong><em>보존기간</em></strong></th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            <tr>
                                                               <td>부정 이용 방지를 위한 결제 이용에 관한 기록</td>
                                                               <td>부정 이용 방지 및 탐지</td>
                                                               <td>5년</td>
                                                            </tr>
                                                            <tr>
                                                               <td>휴대폰 본인확인 서비스 이용에 관한 기록</td>
                                                               <td>정보통신 이용촉진 및 정보보호 등에 관한 법률</td>
                                                               <td>6개월</td>
                                                            </tr>
                                                            <tr>
                                                               <td>계약 또는 청약철회 등에 관한 기록</td>
                                                               <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                                                               <td>5년</td>
                                                            </tr>
                                                            <tr>
                                                               <td>1만원 이상 대금결제 및 재화 등의 공급에 관한 기록</td>
                                                               <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                                                               <td>5년</td>
                                                            </tr>
                                                            <tr>
                                                               <td>1만원 미만 대금결제 및 재화 등의 공급에 관한 기록</td>
                                                               <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                                                               <td>1년</td>
                                                            </tr>
                                                            <tr>
                                                               <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                                                               <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                                                               <td>3년</td>
                                                            </tr>
                                                            <tr>
                                                               <td>웹사이트 방문 또는 자체 간편결제 접속에 관한 기록</td>
                                                               <td>통신비밀보호법에 관한 법률</td>
                                                               <td>3개월</td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                      <h4>4. 이용자는 회사의 개인정보 수집·이용 동의를 거부할 권리가 있습니다.</h4>
                                                      <p>다만, 개인정보의 수집·이용 동의를 거부할 경우 전자결제 서비스의 이용이 제한될 수 있음을 알려드립니다.</p>
                                                   </article>
                                                   <article style="font-size:80%" v-if="showradio3"
                                                      class="mt-3 p-post text--left css-asw555-visuallyHidden">
                                                      <h3>개인정보 제3자 제공 동의</h3>
                                                      <p>토스페이먼츠(이하 회사라 함)는 전자금융거래 서비스의 제공을 위하여 개인정보를 제3자에게 제공하고 있으며 제공 내용은
                                                         이용 서비스 및 결제수단 별로 다음과 같습니다.</p>
                                                      <h4>1. 개인정보를 제공목적, 제공받는 자 및 항목</h4>
                                                      <table>
                                                         <caption>개인정보의 제공목적, 제공받는 자 및 항목</caption>
                                                         <thead>
                                                            <tr>
                                                               <th scope="col" colspan="2">제공목적</th>
                                                               <th scope="col">제공받는 자</th>
                                                               <th scope="col">제공항목</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            <tr>
                                                               <td rowspan="3" colspan="2">신용카드 결제</td>
                                                               <td>[신용카드사] KB카드, 비씨카드, 롯데카드, 삼성카드, NH농협카드, 현대카드, 신한카드,
                                                                  KEB하나카드, 우리카드</td>
                                                               <td rowspan="3">[거래정보] 카드번호, 고객명, 생년월일, 전화번호<br>[비인증결제의 경우
                                                                  거래정보] 카드번호, 유효기간, 생년월일, 카드 비밀번호 앞 2자리)<br>※하나/농협카드는 비밀번호
                                                                  4자리
                                                                  그 외 카드사는 비밀번호 앞 2자리
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td>[은행] 경남은행, 광주은행, 국민은행, 기업은행, 농협은행, 대구은행, 부산은행, KDB산업은행,
                                                                  새마을금고, 수협은행, 신한은행, 신협, 우리은행, 우체국, 전북은행, 제주은행, KEB하나은행,
                                                                  씨티은행, SC제일은행, 산림조합중앙회, 케이뱅크, 카카오뱅크</td>
                                                            </tr>
                                                            <tr>
                                                               <td>[VAN사] (㈜코밴, ㈜코세스 ,㈜다우데이타, 퍼스트데이터 코리아, ㈜에스피씨네트웍스, ㈜엑심베이
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td colspan="2">해외카드 결제</td>
                                                               <td>VISA, MASTER, JCB, AMEX, Discover, Diners, 유니온페이 인터내셔널
                                                               </td>
                                                               <td>카드번호, 유효기간, 전화번호, 회원ID, 카드 비밀번호</td>
                                                            </tr>
                                                            <tr>
                                                               <td colspan="2">간편 결제</td>
                                                               <td>㈜비바리퍼블리카, ㈜에스에스지닷컴, 롯데멤버스㈜, 삼성전자㈜, 엔에이치엔페이코㈜, (주)카카오페이,
                                                                  (주)네이버파이낸셜, (주)블루월넛, Apple Korea</td>
                                                               <td>전화번호, 상품명, 상품금액 [선택] 성함, ID, 이메일주소</td>
                                                            </tr>
                                                            <tr>
                                                               <td colspan="2">신용카드 ARS결제</td>
                                                               <td>㈜엘지유플러스</td>
                                                               <td>카드번호, 전화번호, 유효기간</td>
                                                            </tr>
                                                            <tr>
                                                               <td colspan="2">계좌이체 결제</td>
                                                               <td>금융결제원</td>
                                                               <td>상품명, 결제금액</td>
                                                            </tr>
                                                            <tr>
                                                               <td colspan="2">가상계좌 결제</td>
                                                               <td>[은행] 국민은행, 농협은행, 우리은행, 신한은행, KEB하나은행, 기업은행, 우체국, 부산은행,
                                                                  경남은행, 대구은행, 수협은행</td>
                                                               <td>가상계좌번호</td>
                                                            </tr>
                                                            <tr>
                                                               <td rowspan="2" colspan="2">휴대폰 결제</td>
                                                               <td>[통신사] SK텔레콤㈜, ㈜케이티, ㈜엘지유플러스</td>
                                                               <td rowspan="2">이통사정보, 휴대폰번호, 생년월일, 성별</td>
                                                            </tr>
                                                            <tr>
                                                               <td>[중개사] ㈜다날, 갤럭시아커뮤니케이션즈㈜, 페이레터</td>
                                                            </tr>
                                                            <tr>
                                                               <td colspan="2">상품권 결제</td>
                                                               <td>㈜한국문화진흥, ㈜페이즈북앤라이프</td>
                                                               <td>'구매자ID, 비밀번호'(또는 상품권번호)</td>
                                                            </tr>
                                                            <tr>
                                                               <td rowspan="2">자체간편결제</td>
                                                               <td>신용카드</td>
                                                               <td>[신용카드사] 신한카드, KB카드, 하나카드, BC카드, 삼성카드, NH농협카드, 현대카드,
                                                                  롯데카드, 우리카드, 씨티카드</td>
                                                               <td> 카드번호, CVC, 유효기간, 비밀번호 앞2자리, ※하나/NH농협카드 일 경우 비밀번호 4자리
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td>계좌이체</td>
                                                               <td>금융결제원,<br> [은행] NH농협은행, 농협중앙회, KB은행, KDB산업은행, 우리은행,
                                                                  신한은행, 기업은행, 경남은행, 광주은행, 대구은행, 부산은행, 새마을금고, 수협, 수협중앙회, 신협,
                                                                  우체국, 전북은행, 제주은행, KEB하나은행, 씨티은행, SC제일은행, 카카오뱅크, K뱅크,
                                                                  산림조합중앙회 <br>[증권사] 교보증권, 미래에셋대우, 삼성증권, 신한금융투자, 유진투자증권,
                                                                  이베스트투자증권, 키움증권, 하이투자증권, 한국투자증권, 한화투자증권, 현대차증권, KB증권,
                                                                  NH투자증권, SK증권, 메리츠증권, 대신증권, DB금융투자</td>
                                                               <td>휴대전화번호, 이름, 계좌번호, 계좌 비밀번호</td>
                                                            </tr>
                                                            <tr>
                                                               <td colspan="2">글로벌 서비스</td>
                                                               <td>알리페이, Boku, PayPal</td>
                                                               <td>전화번호, 회원ID, 비밀번호</td>
                                                            </tr>
                                                            <tr>
                                                               <td colspan="2">현금영수증 서비스</td>
                                                               <td>국세청</td>
                                                               <td>[현금영수증 발급정보] 주민등록번호 혹은 사업자번호, 휴대폰번호, 현금영수증 등록카드번호 </td>
                                                            </tr>
                                                            <tr>
                                                               <td colspan="2">오픈뱅킹 서비스<br>(출금이체, 입금이체, 잔액조회) 제공</td>
                                                               <td>금융결제원</td>
                                                               <td>이름, 생년월일, 휴대전화번호, 금융기관명 및 계좌번호, 암호화된 동일인 식별정보(CI), 이메일
                                                               </td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                      <p>※ 회사는 개인정보를 위의 목적으로 제3자 제공하며, 동의받은 목적 이외의 제3자에게 제공하지 처리하지 않습니다.
                                                      </p>
                                                      <h4>2. 개인정보의 보유 및 이용기간</h4>
                                                      <p>개인정보는 원칙적으로 개인정보의 수집·이용 목적이 달성되면 지체없이 파기합니다. 단, 다른 법령에 특별한 규정이 있는
                                                         경우 관련 법령에서 정하는 기간동안 보유합니다.</p>
                                                      <h4>3. 이용자는 회사의 개인정보 제3자 제공에 대한 동의를 거부할 권리가 있습니다.</h4>
                                                      <p>다만, 개인정보의 제3자 제공 동의를 거부할 경우 전자결제지급대행 서비스의 이용이 제한될 수 있음을 알려드립니다.
                                                      </p>
                                                   </article>
                                                </div>
                                             </div>
                                          </div>
                                          <div id="gatsby-announcer"
                                             style="position:absolute;top:0;width:1px;height:1px;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);white-space:nowrap;border:0"
                                             aria-live="assertive" aria-atomic="true"></div>
                                       </div>
                                    </div>
                                 </b-modal>
                                 <b-modal id="service" size="lg" ok-title="동의하기" cancel-title="취소" @ok="checkservice">
                                    <div class="_2mP0n css-1vgo9k0">
                                       <div class="css-ign6bq e1rmugrt4">
                                          <h4 class="css-a42nca e1rmugrt3 text-center">쿠플러스 서비스 이용약관</h4>
                                          <div class="css-9tq7o4 e1rmugrt2 p-4" style="font-size:80%">
                                             <div class="css-0 eud7hl51">
                                                <div class="css-0 e8tfira1">
                                                   <div class="css-axl7p5 e8tfira0">
                                                      <p><br></p>
                                                      <p>제1장 총칙</p>

                                                      <p> 제1조 (목적)<br>
                                                         이 약관은 주식회사 루비데이터랩(이하 “회사”라 합니다)가 모바일 및 PC 기기를 통해 제공하는 서비스 및 이에 부수하는
                                                         네트워크, 웹사이트, 기타 서비스(이하 “서비스”라 합니다)의 이용에 대한 회사와 서비스 이용자의 권리ㆍ의무 및
                                                         책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>

                                                      <p> 제2조 (용어의 정의)<br>
                                                         ① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br>
                                                         1. “회사”라 함은 모바일 및 PC 기기를 통하여 서비스를 제공하는 사업자를 의미합니다.<br>
                                                         2. “회원”이란 이 약관에 따라 이용계약을 체결하고, 회사가 제공하는 서비스를 이용하는 자를 의미합니다.<br>
                                                         3. “임시회원”이란 일부 정보만 제공하고 회사가 제공하는 서비스의 일부만 이용하는 자를 의미합니다.<br>
                                                         4. “모바일 기기”란 콘텐츠를 다운로드 받거나 설치하여 사용할 수 있는 기기로서, 휴대폰, 스마트폰,
                                                         휴대정보단말기(PDA), 태블릿 등을 의미합니다.<br>
                                                         5. “계정정보”란 회원의 회원번호와 외부계정정보, 기기정보, 별명, 프로필 사진, 친구목록 등 회원이 회사에 제공한
                                                         정보와 서비스이용정보, 이용요금 결제 정보 등을 통칭합니다.<br>
                                                         6. “콘텐츠”란 모바일 기기 및 PC로 이용할 수 있도록 회사가 서비스 제공과 관련하여 디지털 방식으로 제작한 유료
                                                         또는 무료의 내용물 일체(서비스 및 네트워크 서비스, 애플리케이션, 서비스 머니. 등)를 의미합니다.<br>
                                                         7. “오픈마켓”이란 모바일 기기에서 콘텐츠를 설치하고 결제할 수 있도록 구축된 전자상거래 환경을 의미합니다.<br>
                                                         8. “애플리케이션”이란 회사가 제공하는 서비스를 이용하기 위하여 모바일 기기를 통해 다운로드 받거나 설치하여 사용하는
                                                         프로그램 일체를 의미합니다.<br>
                                                         9. “서비스”라 함은 회사가 제공하는 서비스의 하나로서 회원이 모바일 및 PC 기기에서 실행하는 메인 서비스 및 이에
                                                         부수하는 서비스를 의미합니다.<br>
                                                         ② 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스별 정책에서 정하는
                                                         바에 의하며, 이에 정하지 아니한 것은 일반적인 상 관례에 따릅니다.</p>

                                                      <p> 제3조 (회사정보 등의 제공)<br>
                                                         회사는 다음 각 호의 사항을 회원이 알아보기 쉽도록 서비스 내에 표시합니다. 다만, 개인정보처리방침과 약관은 회원이
                                                         연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br>
                                                         1. 상호 및 대표자의 성명<br>
                                                         2. 영업소 소재지 주소(회원의 불만을 처리할 수 있는 곳의 주소를 포함한다)<br>
                                                         3. 전화번호, 전자우편주소<br>
                                                         4. 사업자 등록번호<br>
                                                         5. 통신판매업 신고번호<br>
                                                         6. 개인정보처리방침<br>
                                                         7. 서비스 이용약관</p>

                                                      <p> 제4조 (약관의 효력 및 변경)<br>
                                                         ① 회사는 이 약관의 내용을 회원이 알 수 있도록 서비스 내 또는 그 연결화면에 게시합니다. 이 경우 이 약관의 내용
                                                         중 서비스 중단, 청약철회, 환급, 계약 해제․해지, 회사의 면책사항 등과 같은 중요한 내용은 굵은 글씨, 색채, 부호
                                                         등으로 명확하게 표시하거나 별도의 연결화면 등을 통하여 회원이 알아보기 쉽게 처리합니다.<br>
                                                         ② 회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 최소한 그 적용일 7일 이전부터 서비스
                                                         내 또는 그 연결화면에 게시하여 회원에게 공지합니다. 다만, 변경된 내용이 회원에게 불리하거나 중대한 사항의 변경인
                                                         경우에는 그 적용일 30일 이전까지 본문과 같은 방법으로 공지하고 제27조 제1항의 방법으로 회원에게 통지합니다. 이
                                                         경우 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.<br>
                                                         ③ 회사가 약관을 개정할 경우 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 회사는 제2항의
                                                         공지 또는 통지를 할 경우 회원이 개정약관에 대해 동의 또는 거부의 의사표시를 하지 않으면 동의한 것으로 볼 수 있다는
                                                         내용도 함께 공지 또는 통지를 하며, 회원이 이 약관 시행일까지 거부의 의사표시를 하지 않는다면 개정약관에 동의한
                                                         것으로 볼 수 있습니다. 회원이 개정약관에 대해 동의하지 않는 경우 회사 또는 회원은 서비스 이용계약을 해지할 수
                                                         있습니다.<br>
                                                         ④ 회사는 회원이 회사와 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 조치를 취합니다.<br>
                                                         ⑤ 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「서비스산업진흥에 관한
                                                         법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」, 「콘텐츠산업진흥법」 등 관련 법령에 위배하지 않는 범위에서
                                                         이 약관을 개정할 수 있습니다.</p>

                                                      <p> 제5조 (이용계약의 체결 및 적용)<br>
                                                         ① 이용계약은 회원이 되고자 하는 자(이하 “가입신청자”라 합니다.)가 이 약관의 내용에 대하여 동의를 한 다음 서비스
                                                         이용 신청을 하고, 회사가 그 신청에 대해서 승낙함으로써 체결됩니다.<br>
                                                         ② 회사는 가입신청자의 신청에 대하여 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호의 어느 하나에 해당하는 이용
                                                         신청에 대해서는 승낙을 거절할 수 있습니다.<br>
                                                         1. 이용신청서 내용을 허위로 기재하거나 이용신청 요건을 충족하지 못한 경우<br>
                                                         2. 회사가 서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인 방법을 통해 서비스를 이용하는 경우<br>
                                                         3. 「서비스산업진흥에 관한 법률」등 관련 법령에서 금지하는 행위를 할 목적으로 신청 하는 경우<br>
                                                         4. 사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 신청한 경우<br>
                                                         5. 부정한 용도로 서비스를 이용하고자 하는 경우<br>
                                                         6. 영리를 추구할 목적으로 서비스를 이용하고자 하는 경우<br>
                                                         7. 그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우<br>
                                                         ③ 회사는 다음 각 호의 어느 하나에 해당하는 경우 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.<br>
                                                         1. 회사의 설비에 여유가 없거나, 특정 모바일 기기의 지원이 어렵거나, 기술적 장애가 있는 경우.<br>
                                                         2. 서비스 상의 장애 또는 서비스 이용요금, 결제수단의 장애가 발생한 경우.<br>
                                                         3. 그 밖의 각 호에 준하는 사유로서 이용신청의 승낙이 어렵다고 판단되는 경우</p>

                                                      <p> 제6조 (약관 외 준칙)<br>
                                                         이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래 등에서의 소비자보호에 관한 법률」,「약관의
                                                         규제에 관한 법률」,「서비스 산업진흥에 관한 법률」,「정보통신망이용촉진 및 정보보호 등에 관한
                                                         법률」,「콘텐츠산업진흥법」 등 관련 법령 또는 상 관례에 따릅니다.</p>

                                                      <p> 제7조 (운영정책)<br>
                                                         ① 약관을 적용하기 위하여 필요한 사항과 약관에서 구체적 범위를 정하여 위임한 사항을 서비스 운영정책(이하
                                                         “운영정책”이라 합니다)으로 정할 수 있습니다.<br>
                                                         ② 회사는 운영정책의 내용을 회원이 알 수 있도록 서비스 내 또는 그 연결화면에 게시합니다.<br>
                                                         ③ 운영정책을 개정하는 경우에는 제4조 제2항의 절차에 따릅니다. 다만, 운영정책 개정내용이 다음 각 호의 어느 하나에
                                                         해당하는 경우에는 제2항의 방법으로 사전에 공지합니다.<br>
                                                         1. 약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우<br>
                                                         2. 회원의 권리·의무와 관련 없는 사항을 개정하는 경우<br>
                                                         3. 운영정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고 회원이 예측할 수 있는 범위 내에서 운영정책을
                                                         개정하는 경우</p>

                                                      <p> 제2장 개인정보 관리</p>

                                                      <p> 제8조 (개인정보의 보호 및 사용)<br>
                                                         ① 회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력하며, 개인정보의 보호 및 사용에 대해서는
                                                         관련 법령 및 회사의 개인정보처리방침에 따릅니다. 다만, 회사가 제공하는 서비스 이외의 링크된 서비스에서는 회사의
                                                         개인정보처리방침이 적용되지 않습니다.<br>
                                                         ② 서비스의 특성에 따라 회원의 개인정보와 관련이 없는 별명‧캐릭터 사진‧상태정보 등 자신을 소개하는 내용이 공개될 수
                                                         있습니다.<br>
                                                         ③ 회사는 관련 법령에 의해 관련 국가기관 등의 요청이 있는 경우를 제외하고는 회원의 개인정보를 본인의 동의 없이
                                                         타인에게 제공하지 않습니다.<br>
                                                         ④ 회사는 회원의 귀책사유로 개인정보가 유출되어 발생한 피해에 대하여 책임을 지지 않습니다.</p>

                                                      <p> 제3장 이용계약 당사자의 의무</p>

                                                      <p> 제9조 (회사의 의무)<br>
                                                         ① 회사는 관련 법령, 이 약관에서 정하는 권리의 행사 및 의무의 이행을 신의에 따라 성실하게 준수합니다.<br>
                                                         ② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며
                                                         개인정보처리방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한 경우를 제외하고는 회원의 개인정보가
                                                         제3자에게 공개 또는 제공되지 않도록 합니다.<br>
                                                         ③ 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이
                                                         멸실․훼손된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체
                                                         없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.</p>

                                                      <p> 제10조 (회원의 의무)<br>
                                                         ① 회원은 회사에서 제공하는 서비스의 이용과 관련하여 다음 각 호에 해당하는 행위를 해서는 안 됩니다.<br>
                                                         1. 이용신청 또는 회원 정보 변경 시 허위사실을 기재하는 행위<br>
                                                         2. 회사가 제공하지 않는 서비스나 비정상적인 방법을 통해 사이버 자산을 매매 또는 증여하거나, 이를 취득하여 이용하는
                                                         행위<br>
                                                         3. 회사의 직원이나 운영자를 가장하거나 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위, 타인으로
                                                         가장하거나 타인과의 관계를 허위로 명시하는 행위<br>
                                                         4. 타인의 신용카드⋅유/무선 전화⋅은행 계좌 등을 도용하여 유료 콘텐츠를 구매하는 행위, 다른 회원의 ID 및
                                                         비밀번호를 부정사용하는 행위<br>
                                                         5. 다른 회원의 개인정보를 무단으로 수집⋅저장⋅게시 또는 유포하는 행위<br>
                                                         6. 도박 등 사행행위를 하거나 유도하는 행위, 음란⋅저속한 정보를 교류⋅게재하거나 음란 사이트를 연결(링크)하는
                                                         행위, 수치심⋅혐오감 또는 공포심을 일으키는 말⋅소리⋅글⋅그림⋅사진 또는 영상을 타인에게 전송 또는 유포하는 행위 등
                                                         서비스를 불건전하게 이용하는 행위<br>
                                                         7. 서비스를 무단으로 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의 용도로 이용하는
                                                         행위<br>
                                                         8. 회사의 서비스를 이용하여 얻은 정보를 무단으로 복제․유통․조장하거나 상업적으로 이용하는 행위, 알려지거나 알려지지
                                                         않은 버그를 악용하여 서비스를 이용하는 행위<br>
                                                         9. 타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과 관련하여 타인에게 피해를 입히는 행위<br>
                                                         10. 회사나 타인의 지적재산권 또는 초상권을 침해하는 행위, 타인의 명예를 훼손하거나 손해를 가하는 행위<br>
                                                         11. 법령에 의하여 전송 또는 게시가 금지된 정보(컴퓨터 프로그램)나 컴퓨터 소프트웨어⋅하드웨어 또는 전기통신장비의
                                                         정상적인 작동을 방해⋅파괴할 목적으로 고안된 바이러스⋅컴퓨터 코드⋅파일⋅프로그램 등을 고의로 전송⋅게시⋅유포 또는
                                                         사용하는 행위<br>
                                                         12. 회사로부터 특별한 권리를 부여 받지 않고 애플리케이션을 변경하거나, 애플리케이션에 다른 프로그램을
                                                         추가⋅삽입하거나, 서버를 해킹⋅역설계하거나, 소스 코드나 애플리케이션 데이터를 유출⋅변경하거나, 별도의 서버를
                                                         구축하거나, 웹사이트의 일부분을 임의로 변경⋅도용하여 회사를 사칭하는 행위<br>
                                                         13. 그 밖에 관련 법령에 위반되거나 선량한 풍속 기타 사회통념에 반하는 행위<br>
                                                         ② 회원의 계정 및 모바일 기기에 관한 관리 책임은 회원에게 있으며, 이를 타인이 이용하도록 하게 하여서는 안 됩니다.
                                                         모바일 기기의 관리 부실이나 타인에게 이용을 승낙함으로 인해 발생하는 손해에 대해서 회사는 책임을 지지
                                                         않습니다.<br>
                                                         ③ 회원은 각 오픈마켓에서 부정한 결제가 이루어지지 아니하도록 결제 비밀번호 기능을 설정하고 관리하여야 합니다. 회원의
                                                         부주의로 인하여 발생하는 손해에 대해 회사는 책임지지 않습니다<br>
                                                         ④ 회사는 다음 각 호의 행위의 구체적인 내용을 정할 수 있으며, 회원은 이를 따라야 합니다.<br>
                                                         1. 회원의 계정명, 기타 서비스 내에서 사용하는 명칭<br>
                                                         2. 채팅내용과 방법<br>
                                                         3. 게시판이용 및 서비스이용 방법<br>
                                                         4. 카카오, 페이스북, 구글플러스 등 외부 모바일 플랫폼 제휴 서비스 정책</p>

                                                      <p>
                                                         제4장 서비스 이용 및 이용제한</p>

                                                      <p> 제11조 (서비스의 제공)<br>
                                                         ① 회사는 제5조의 규정에 따라 이용계약이 완료된 회원에게 그 즉시 서비스를 이용할 수 있도록 합니다. 다만, 일부
                                                         서비스의 경우 회사의 필요에 따라 지정된 일자부터 서비스를 개시할 수 있습니다.<br>
                                                         ② 회사는 회원에게 서비스를 제공할 때 이 약관에 정하고 있는 서비스를 포함하여 기타 부가적인 서비스를 함께 제공할 수
                                                         있습니다.<br>
                                                         ③ 회사는 회원의 등급을 구분하고 이용시간, 이용횟수, 제공 서비스의 범위 등을 세분화하여 이용에 차등을 둘 수
                                                         있습니다.</p>

                                                      <p> 제12조 (서비스의 이용)<br>
                                                         ① 서비스는 회사의 영업방침에 따라 정해진 시간 동안 제공합니다. 회사는 서비스 제공시간을 애플리케이션 초기화면이나
                                                         서비스 공지사항에 적절한 방법으로 안내합니다.<br>
                                                         ② 제1항에도 불구하고 회사는 다음 각 호의 경우에는 서비스의 전부 또는 일부를 일시 정지할 수 있습니다. 이 경우
                                                         회사는 사전에 그 정지의 사유와 기간을 애플리케이션 초기화면이나 서비스 공지사항 등에 공지합니다. 다만, 사전에 공지할
                                                         수 없는 부득이한 사정이 있는 경우 사후에 공지할 수 있습니다.<br>
                                                         1. 시스템 정기점검, 서버의 증설 및 교체, 네트워크의 불안정 등의 시스템 운영상 필요한 경우<br>
                                                         2. 정전, 서비스 설비의 장애, 서비스 이용폭주, 기간통신사업자의 설비 보수 또는 점검 등으로 인하여 정상적인 서비스
                                                         제공이 불가능한 경우<br>
                                                         3. 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태 등 회사가 통제할 수 없는 상황이 발생한 경우<br>
                                                         ③ 회사는 모바일 기기를 위한 전용 애플리케이션 또는 네트워크를 이용하여 서비스를 제공합니다. 회원은 애플리케이션을
                                                         다운로드하여 설치하거나 네트워크를 이용하여 무료 또는 유료로 서비스를 이용할 수 있습니다.<br>
                                                         ④ 유료 콘텐츠의 경우에는 해당 서비스에 명시된 요금을 지급하여야 이용할 수 있습니다. 네트워크를 통해 애플리케이션을
                                                         다운로드하거나 서비스를 이용하는 경우에는 가입한 이동통신사에서 정한 별도의 요금이 발생할 수 있습니다.<br>
                                                         ⑤ 다운로드하여 설치한 애플리케이션 또는 네트워크를 통해 이용하는 서비스의 경우에는 모바일 기기 또는 이동통신사의
                                                         특성에 맞도록 제공됩니다. 모바일 기기의 변경․번호 변경 또는 해외 로밍의 경우에는 콘텐츠의 전부 또는 일부의 이용이
                                                         불가능할 수 있으며, 이 경우 회사는 책임을 지지 않습니다.<br>
                                                         ⑥ 다운로드하여 설치한 애플리케이션 또는 네트워크를 통해 이용하는 서비스의 경우에는 백그라운드 작업이 진행될 수
                                                         있습니다. 이 경우 모바일 기기 또는 이동통신사의 특성에 맞도록 추가요금이 발생할 수 있으며 이와 관련하여 회사는
                                                         책임을 지지 않습니다.</p>

                                                      <p> 제13조 (서비스의 변경 및 중단)<br>
                                                         ① 회사는 원활한 서비스 제공을 위해 운영상 또는 기술상의 필요에 따라 서비스를 변경할 수 있으며, 변경 전에 해당
                                                         내용을 서비스 내에 공지합니다. 다만, 버그․오류 등의 수정이나 긴급 업데이트 등 부득이하게 변경할 필요가 있는 경우
                                                         또는 중대한 변경에 해당하지 않는 경우에는 사후에 공지할 수 있습니다.<br>
                                                         ② 회사는 영업양도․분할․합병 등에 따른 영업의 폐지, 서비스 제공의 계약만료, 당해 서비스의 현저한 수익 악화 등
                                                         경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우에는 서비스 전부를 중단할 수 있습니다. 이 경우 중단일자
                                                         30일 이전까지 중단일자․중단사유․보상조건 등을 애플리케이션 초기화면 또는 그 연결화면을 통해 공지하고 제27조
                                                         제1항의 방법으로 회원에게 통지합니다.<br>
                                                         ③ 제2항의 경우 회사는 사용하지 않았거나 사용기간이 남아 있는 유료 아이템에 대해 제24조 제3항에 따라 환급합니다.
                                                      </p>

                                                      <p> 제14조 (정보의 수집 등)<br>
                                                         ① 회사는 회원간에 이루어지는 채팅 내용을 저장․보관할 수 있으며 이 정보는 회사만이 보유합니다. 회사는 회원간의 분쟁
                                                         조정, 민원 처리 또는 질서의 유지를 위한 경우에 한하여, 제3자는 법령에 따라 권한이 부여된 경우에 한하여 이 정보를
                                                         열람할 수 있습니다.<br>
                                                         ② 회사 또는 제3자가 제1항에 따라 채팅 정보를 열람할 경우 회사는 사전에 열람의 사유 및 범위를 해당 회원에게
                                                         고지합니다. 다만, 제10조 제1항에 따른 금지행위의 조사․처리․확인 또는 그 행위로 인한 피해 구제와 관련하여 이
                                                         정보를 열람해야 할 경우에는 사후에 고지할 수 있습니다.<br>
                                                         ③ 회사는 서비스의 원활하고 안정적인 운영 및 서비스 품질의 개선을 위하여 회원의 개인정보를 제외한 회원의 모바일 기기
                                                         정보(설정,사양,운영체제, 버전 등)를 수집 ‧ 활용할 수 있습니다.<br>
                                                         ④ 회사는 서비스 개선 및 회원 대상 서비스 소개 등을 위한 목적으로 회원에게 추가정보를 요청할 수 있습니다. 이
                                                         요청에 대해 회원은 승낙하거나 거절할 수 있으며, 회사가 이 요청을 할 경우에는 회원이 이 요청을 거절할 수 있다는
                                                         뜻을 함께 고지합니다.</p>

                                                      <p> 제15조 (광고의 제공)<br>
                                                         ① 회사는 서비스의 운영과 관련하여 서비스 내에 광고를 게재할 수 있습니다. 또한 수신에 동의한 회원에 한하여
                                                         전자우편, 문자서비스(LMS/SMS), 푸시메시지(Push Notification) 등의 방법으로 광고성 정보를 전송할
                                                         수 있습니다. 이 경우 회원은 언제든지 수신을 거절할 수 있으며, 회사는 회원의 수신 거절 시 광고성 정보를 발송하지
                                                         아니합니다.<br>
                                                         ② 회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이 제공하는 광고나 서비스에 연결될 수 있습니다.<br>
                                                         ③ 제2항에 따라 타인이 제공하는 광고나 서비스에 연결될 경우 해당 영역에서 제공하는 서비스는 회사의 서비스 영역이
                                                         아니므로 회사가 신뢰성, 안정성 등을 보장하지 않으며, 그로 인한 회원의 손해에 대하여도 회사는 책임을 지지 않습니다.
                                                         다만, 회사가 고의 또는 중과실로 손해의 발생을 용이하게 하거나 손해 방지를 위한 조치를 취하지 아니한 경우에는
                                                         그러하지 아니합니다.</p>

                                                      <p> 제16조 (저작권 등의 귀속)<br>
                                                         ① 회사가 제작한 서비스 내의 콘텐츠에 대한 저작권과 기타 지적재산권은 회사에 귀속합니다.<br>
                                                         ② 회원은 회사가 제공하는 서비스를 이용하여 얻은 정보 중에서 회사 또는 제공업체에 지적재산권이 귀속된 정보를 회사
                                                         또는 제공업체의 사전 동의 없이 복제⋅전송 등의 방법(편집, 공표, 공연, 배포, 방송, 2차적 저작물 작성 등을
                                                         포함합니다. 이하 같습니다)에 의하여 영리목적으로 이용하거나 타인에게 이용하게 하여서는 안 됩니다.<br>
                                                         ③ 회원은 서비스 내에서 보여지거나 서비스와 관련하여 회원 또는 다른 이용자가 애플리케이션 또는 서비스를 통해 업로드
                                                         또는 전송하는 대화 텍스트를 포함한 커뮤니케이션, 이미지, 사운드 및 모든 자료 및 정보(이하 “이용자 콘텐츠”라
                                                         합니다.)에 대하여 회사가 다음과 같은 방법과 조건으로 이용하는 것을 허락합니다.<br>
                                                         1. 해당 이용자 콘텐츠를 이용, 편집 형식의 변경 및 기타 변형하는 것(공표, 복제, 공연, 전송, 배포, 방송,
                                                         2차적 저작물 작성 등 어떠한 형태로든 이용 가능하며, 이용기간과 지역에는 제한이 없음)<br>
                                                         2. 이용자 콘텐츠를 제작한 이용자의 사전 동의 없이 거래를 목적으로 이용자 콘텐츠를 판매, 대여, 양도 행위를 하지
                                                         않음<br>
                                                         ④ 서비스와 일체화되지 않은 회원의 이용자 콘텐츠(예컨대, 일반게시판 등에서의 게시물)에 대하여 회사는 회원의 명시적인
                                                         동의가 없이 이용하지 않으며, 회원은 언제든지 이러한 이용자 콘텐츠를 삭제할 수 있습니다.<br>
                                                         ⑤ 회사는 회원이 게시하거나 등록하는 서비스 내의 게시물에 대해 제10조 제1항에 따른 금지행위에 해당된다고 판단되는
                                                         경우에는 사전 통지 없이 이를 삭제 또는 이동하거나 그 등록을 거절할 수 있습니다.<br>
                                                         ⑥ 회사가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 회원은 회사에 해당 정보의 삭제 또는 반박
                                                         내용의 게재를 요청할 수 있습니다. 이 경우 회사는 신속하게 필요한 조치를 취하고 이를 신청인에게 통지합니다.<br>
                                                         ⑦ 이 조는 회사가 서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도 지속적으로 적용됩니다.</p>

                                                      <p> 제17조 (유료 콘텐츠의 구매, 사용기간 및 이용)<br>
                                                         ① 서비스 내에서 회원이 구매한 유료 콘텐츠는 해당 애플리케이션을 다운로드 받거나 설치한 모바일 기기에서만 이용할 수
                                                         있습니다.<br>

                                                         ② 회원이 구매한 유료 콘텐츠의 이용기간은 구매 시 명시된 기간에 따릅니다. 다만, 제13조 제2항에 따라 서비스
                                                         중단이 이루어지는 경우 기간의 정함이 없는 유료 콘텐츠의 이용기간은 서비스 중단 공지 시 공지된 서비스의 중단일자까지로
                                                         합니다.</p>
                                                         ③ 구독결제일은 1일~28일의 경우 결제일을 기준으로 다음달 동일 날짜에 결제됩니다. (1/5일 결제한 경우 2/5일에 결제됨)<br>
                                                         ④ 구독결제일이 해당 월말일 보다 늦은 경우 해당 월말에 결제됩니다. (1/31일에 결제한 경우 다음 결제일은 2/28, 3/31, 4/30일..)<br>
                                                         ⑤ 구독결제 취소는 설정에서 구독취소/재개를 통해 변경할 수 있습니다.<br>

                                                      <p> 제18조 (회원에 대한 서비스 이용제한)<br>
                                                         ① 회원은 제10조에 따른 회원의 의무를 위반하는 행위를 하여서는 안 되며, 해당 행위를 하는 경우에 회사는 다음 각
                                                         호의 구분에 따른 회원의 서비스 이용제한, 관련 정보(글, 사진, 영상 등) 삭제 및 기타의 조치를 포함한 이용제한
                                                         조치를 할 수 있습니다. 이용제한 조치가 이루어지는 구체적인 사유 및 절차는 제19조 제1항에 따라 개별 서비스의
                                                         운영정책에서 정합니다.<br>
                                                         1. 일부 권한 제한 : 일정기간 채팅 등 일정 권한을 제한<br>
                                                         2. 캐릭터 이용제한 : 일정기간 또는 영구히 회원 캐릭터의 이용을 제한<br>
                                                         3. 계정 이용제한 : 일정기간 또는 영구히 회원 계정의 이용을 제한<br>
                                                         4. 회원 이용제한 : 일정기간 또는 영구히 회원의 서비스 이용을 제한<br>
                                                         ② 제1항의 이용제한이 정당한 경우에 회사는 이용제한으로 인하여 회원이 입은 손해를 배상하지 않습니다.<br>
                                                         ③ 회사는 다음 각 호의 사유에 대한 조사가 완료될 때까지 해당 계정의 서비스 이용을 정지할 수 있습니다.<br>
                                                         1. 계정이 해킹 또는 도용당했다는 정당한 신고가 접수된 경우<br>
                                                         2. 불법프로그램 사용자 또는 작업장 등 위법행위자로 의심되는 경우<br>
                                                         3. 그 밖에 각 호에 준하는 사유로 서비스 이용의 잠정조치가 필요한 경우<br>
                                                         ④ 제3항의 조사가 완료된 후, 유료 서비스의 경우에는 정지된 시간만큼 회원의 이용시간을 연장하거나 그에 상당하는
                                                         유료서비스 또는 캐쉬 등으로 보상합니다. 다만, 회원이 제3항 각 호의 사유에 해당하는 경우에는 그러하지 아니합니다.
                                                      </p>

                                                      <p> 제19조 (이용제한 조치의 사유와 절차)<br>
                                                         ① 회사는 제18조 제1항에 따른 이용제한 조치의 구체적인 사유 및 절차를 제10조 제1항에 따른 금지행위의
                                                         내용․정도․횟수․결과 등을 고려하여 운영정책으로 정합니다.<br>
                                                         ② 회사가 제18조 제1항에서 정한 이용제한 조치를 하는 경우에는 다음 각 호의 사항을 회원에게 사전 통지합니다.
                                                         다만, 긴급히 조치할 필요가 있는 경우에는 사후에 통지할 수 있습니다.<br>
                                                         1. 이용제한 조치의 사유<br>
                                                         2. 이용제한 조치의 유형 및 기간<br>
                                                         3. 이용제한 조치에 대한 이의신청 방법</p>

                                                      <p> 제20조 (이용제한 조치에 대한 이의신청 절차)<br>
                                                         ① 회원이 회사의 이용제한 조치에 불복하고자 할 때에는 이 조치의 통지를 받은 날부터 14일 이내에 불복 이유를 기재한
                                                         이의 신청서를 서면, 전자우편 또는 이에 준하는 방법으로 회사에 제출하여야 합니다.<br>
                                                         ② 회사는 제1항의 이의신청서를 접수한 날부터 15일 이내에 불복 이유에 대하여 서면, 전자우편 또는 이에 준하는
                                                         방법으로 답변합니다. 다만, 회사는 이 기간 내에 답변이 어려운 경우에는 그 사유와 처리일정을 통지합니다.<br>
                                                         ③ 회사는 불복 이유가 타당한 경우에는 이에 따른 조치를 취합니다.</p>

                                                      <p> 제5장 청약철회, 과오납금의 환급 및 이용계약의 해지</p>

                                                      <p> 제21조 (대금결제)<br>
                                                         ① 콘텐츠에 대한 구매 대금의 부과와 납부는 원칙적으로 이동통신사나 오픈마켓 사업자 등에서 정하는 정책이나 방법에
                                                         따릅니다. 또한 각 결제수단별 한도가 회사나 오픈마켓 사업자가 정하는 정책 또는 정부의 방침에 따라 부여되거나 조정될
                                                         수 있습니다.<br>
                                                         ② 콘텐츠의 구매대금을 외화로 결제하는 경우에는 환율‧수수료 등으로 인하여 실제 청구금액이 서비스의 상점 등에서 표시된
                                                         가격과 달라질 수 있습니다.</p>

                                                      <p> 제22조 (청약 철회 등)<br>
                                                         ① 회사와 유료 콘텐츠의 구매에 관한 계약을 체결한 회원은 구매계약일과 콘텐츠 이용 가능일 중 늦은 날부터 7일 이내에
                                                         별도의 수수료‧위약금 등의 부담 없이 청약철회를 할 수 있습니다.<br>
                                                         ② 회원은 다음 각 호에 해당하는 경우에는 회사의 의사에 반하여 제1항에 따른 청약철회를 할 수 없습니다. 다만,
                                                         가분적 콘텐츠로 구성된 구매계약의 경우에는 가분적 콘텐츠 중 다음 각 호에 해당하지 아니하는 나머지 부분에 대하여는
                                                         그러하지 아니합니다.<br>
                                                         1. 구매 즉시 사용되거나 적용되는 유료 콘텐츠<br>
                                                         2. 추가혜택이 제공되는 경우에 그 추가 혜택이 사용된 콘텐츠<br>
                                                         3. 개봉행위를 사용으로 볼 수 있거나 개봉 시 효용이 결정되는 콘텐츠의 개봉행위가 있는 경우<br>
                                                         ③ 회사는 제2항 각 호의 규정에 따라 청약철회가 불가능한 콘텐츠의 경우에는 그 사실을 회원이 쉽게 알 수 있는 곳에
                                                         명확하게 표시하고, 해당 콘텐츠의 시험사용 상품을 제공(한시적 이용의 허용, 체험용 제공 등)하거나 이에 대한 제공이
                                                         곤란한 경우에는 콘텐츠에 관한 정보 제공함으로써 회원의 청약철회의 권리행사가 방해받지 아니하도록 조치합니다. 만약
                                                         회사가 이러한 조치를 하지 아니한 경우에는 제2항 각 호의 청약철회 제한사유에도 불구하고 회원은 청약철회를 할 수
                                                         있습니다.<br>
                                                         ④ 회원은 제1항 및 제2항에도 불구하고 구매한 유료 콘텐츠의 내용이 표시․광고의 내용과 다르거나 구매계약의 내용과
                                                         다르게 이행된 경우에 해당 콘텐츠가 이용 가능하게 된 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터
                                                         30일 이내에 청약철회를 할 수 있습니다.<br>
                                                         ⑤ 회원이 청약철회를 할 경우 회사는 플랫폼사업자 또는 오픈마켓 사업자를 통해 구매내역을 확인합니다. 또한 회사는
                                                         회원의 정당한 철회 사유를 확인하기 위해 회원에게서 제공받은 정보를 통해 회원에게 연락할 수 있으며, 추가적인 증빙을
                                                         요구할 수 있습니다.<br>
                                                         ⑥ 제1항부터 제4항까지의 규정에 따라 청약철회가 이루어질 경우 회사는 지체 없이 회원의 유료 콘텐츠를 회수하고
                                                         3영업일 이내에 대금을 환급합니다. 이 경우 회사가 환급을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의
                                                         소비자보호에 관한 법률」 및 같은 법 시행령 제21조의3에서 정하는 이율을 곱하여 산정한 지연이자를 지급합니다.<br>
                                                         ⑦ 미성년자가 모바일 기기에서 콘텐츠 구매계약을 체결하는 경우, 회사는 법정대리인의 동의가 없으면 미성년자 본인 또는
                                                         법정대리인이 그 계약을 취소할 수 있다는 내용을 고지하며, 미성년자가 법정대리인의 동의 없이 구매계약을 체결한 때에는
                                                         미성년자 본인 또는 법정대리인은 회사에 그 계약을 취소할 수 있습니다. 다만, 미성년자가 법정대리인이 범위를 정하여
                                                         처분을 허락한 재산으로 콘텐츠를 구매한 경우 또는 미성년자가 속임수로써 자기를 성년자로 믿게 하거나 법정대리인의 동의가
                                                         있는 것으로 믿게 한 경우에는 취소할 수 없습니다.<br>
                                                         ⑧ 콘텐츠 구매계약의 당사자가 미성년자인지 여부는 결제가 진행된 모바일 기기, 결제 실행자 정보, 결제 수단 명의자
                                                         등을 근거로 판단합니다. 또한 회사는 정당한 취소인지를 확인하기 위해 미성년자 및 법정대리인임을 증명할 수 있는 서류의
                                                         제출을 요청할 수 있습니다.</p>

                                                      <p> 제23조 (과오납금의 환급)<br>
                                                         ① 회사는 과오납금이 발생하는 경우 과오납금을 회원에게 환급합니다. 다만, 과오납금이 회사의 고의 또는 과실 없이
                                                         회원의 과실로 인하여 발생한 경우에는 그 환급에 소요되는 실제 비용은 합리적인 범위 내에서 회원이 부담합니다.<br>
                                                         ② 애플리케이션을 통한 결제는 오픈마켓 사업자가 제공하는 결제방식에 따르며, 결제 과정에서 과오납금이 발생하는 경우,
                                                         회사 또는 오픈마켓 사업자에게 환급을 요청하여야 합니다.<br>
                                                         ③ 애플리케이션의 다운로드 또는 네트워크 서비스의 이용으로 인해 발생한 통신요금(통화료, 데이터 통화료 등)은 환급
                                                         대상에서 제외될 수 있습니다.<br>
                                                         ④ 환급은 서비스를 이용하고 있는 모바일 기기의 운영체제 종류에 따라 각 오픈마켓 사업자 또는 회사의 환급정책에 따라
                                                         진행됩니다.<br>
                                                         ⑤ 회사는 과오납금의 환급을 처리하기 위해 회원에게서 제공받은 정보를 통해 회원에게 연락할 수 있으며, 필요한 정보의
                                                         제공을 요청할 수 있습니다. 회사는 회원으로부터 환급에 필요한 정보를 받은 날부터 3영업일 이내에 환급합니다.</p>

                                                      <p> 제24조 (계약 해지 등)<br>
                                                         ① 회원은 언제든지 서비스 이용을 원하지 않는 경우 회원 탈퇴를 통해 이용계약을 해지할 수 있습니다. 회원탈퇴로 인해
                                                         회원이 서비스 내에서 보유한 이용정보는 모두 삭제되어 복구가 불가능하게 됩니다.<br>
                                                         ② 회사는 회원이 이 약관 및 그에 따른 운영정책, 서비스 정책에서 금지하는 행위를 하는 등 본 계약을 유지할 수 없는
                                                         중대한 사유가 있는 경우에는 상당한 기간 전에 최고하고 기간을 정하여 서비스 이용을 중지하거나 이용계약을 해지할 수
                                                         있습니다.<br>
                                                         ③ 제1항 및 제2항에 따른 환급 및 손해배상은 「콘텐츠이용자보호지침」에 따라 처리합니다.<br>
                                                         ④ 회사는 최근의 서비스 이용일부터 연속하여 1년 동안 회사의 서비스를 이용하지 않은 회원(이하 “휴면계정”이라
                                                         합니다)의 개인정보를 보호하기 위해 이용계약을 해지하고 회원의 개인정보 파기 등의 조치를 취할 수 있습니다. 이 경우
                                                         조치일 30일 전까지 계약해지, 개인정보 파기 등의 조치가 취해진다는 사실 및 파기될 개인정보 등을 회원에게
                                                         통지합니다.</p>

                                                      <p> 제6장 손해배상 및 면책조항 등</p>

                                                      <p> 제25조 (손해배상)<br>
                                                         ① 회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만,
                                                         고의 또는 과실이 없는 경우에는 그러하지 아니 합니다<br>
                                                         ② 회사가 개별서비스 제공자와 제휴 계약을 맺고 회원에게 개별서비스를 제공하는 경우에 회원이 이 개별서비스 이용약관에
                                                         동의를 한 뒤 개별서비스 제공자의 고의 또는 과실로 인해 회원에게 손해가 발생한 경우에 그 손해에 대해서는 개별서비스
                                                         제공자가 책임을 집니다.</p>

                                                      <p> 제26조 (회사의 면책)<br>
                                                         ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관하여 책임을
                                                         지지 않습니다.<br>
                                                         ② 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 기타 이에 준하는 사유로 발생한 손해에 대하여 책임을 지지
                                                         않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.<br>
                                                         ③ 회사는 회원의 고의 또는 과실로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다. 다만, 회원에게
                                                         부득이하거나 정당한 사유가 있는 경우에는 그러하지 아니합니다.<br>
                                                         ④ 회원이 서비스와 관련하여 게재한 정보나 자료 등의 신뢰성, 정확성 등에 대하여 회사는 고의 또는 중대한 과실이 없는
                                                         한 책임을 지지 않습니다.<br>
                                                         ⑤ 회사는 회원이 다른 회원 또는 타인과 서비스를 매개로 발생한 거래나 분쟁에 대해 개입할 의무가 없으며, 이로 인한
                                                         손해에 대해 책임을 지지 않습니다.<br>
                                                         ⑥ 회사는 무료로 제공되는 서비스 이용과 관련하여 회원에게 발생한 손해에 대해서는 책임을 지지 않습니다. 그러나 회사의
                                                         고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.<br>
                                                         ⑦ 회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.<br>
                                                         ⑧ 회사는 회원의 데이터, 머니 등의 손실에 대하여 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한
                                                         경우에는 그러하지 아니합니다.<br>
                                                         ⑨ 회사는 회원이 모바일 기기 비밀번호, 오픈마켓 사업자가 제공하는 비밀번호 등을 관리하지 않아 발생하는 제3자 결제에
                                                         대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.<br>
                                                         ⑩ 회원이 모바일 기기의 변경, 모바일 기기의 번호 변경, 운영체제(OS) 버전의 변경, 해외 로밍, 통신사 변경
                                                         등으로 인해 콘텐츠 전부나 일부의 기능을 이용할 수 없는 경우 회사는 이에 대해 책임을 지지 않습니다. 다만, 회사의
                                                         고의 또는 과실에 의한 경우에는 그러하지 아니합니다.<br>
                                                         ⑪ 회원이 회사가 제공하는 콘텐츠나 계정정보를 삭제한 경우 회사는 이에 대해 책임을 지지 않습니다. 다만, 회사의 고의
                                                         또는 과실에 의한 경우에는 그러하지 아니합니다.<br>
                                                         ⑫ 회사는 임시회원이 서비스 이용으로 발생한 손해에 대해서는 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에
                                                         의한 경우에는 그러하지 아니합니다.</p>

                                                      <p> 제27조 (회원에 대한 통지)<br>
                                                         ① 회사가 회원에게 통지를 하는 경우 회원의 전자우편주소, 전자메모, 서비스 내 쪽지, 문자메시지(LMS/SMS)
                                                         등으로 할 수 있습니다.<br>
                                                         ② 회사는 회원 전체에게 통지를 하는 경우 7일 이상 서비스 내에 게시하거나 팝업화면 등을 제시함으로써 제1항의 통지에
                                                         갈음할 수 있습니다.</p>

                                                      <p> 제28조 (재판권 및 준거법) 이 약관은 대한민국 법률에 따라 규율되고 해석됩니다. 회사와 회원 간에 발생한 분쟁으로
                                                         소송이 제기되는 경우에는 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.</p>

                                                      <p> 제29조 (회원의 고충처리 및 분쟁해결)<br>
                                                         ① 회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을 서비스 내 또는 그 연결화면에 안내합니다.
                                                         회사는 이러한 회원의 의견이나 불만을 처리하기 위한 전담인력을 운영합니다.<br>
                                                         ② 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게
                                                         처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 회원에게 장기간이 소요되는 사유와 처리일정을 서비스 내 공지하거나
                                                         제27조 제1항에 따라 통지합니다.<br>
                                                         ③ 회사와 회원 간에 분쟁이 발생하여 제3의 분쟁조정기관이 조정할 경우 회사는 이용제한 등 회원에게 조치한 사항을
                                                         성실히 증명하고, 조정기관의 조정에 따를 수 있습니다.<br>
                                                      </p>
                                                      <p><br></p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="css-1azakc e1rmugrt1"></div>
                                       </div>
                                    </div>
                                 </b-modal>



                                 <p class="mt-3">
                                    처음 방문하신 분은 회원가입 후 이용해주세요<br>
                                    <router-link :to="{ name: 'auth.register' }"
                                       class="text-primary mr-3"><u>회원가입</u></router-link>
                                    <a style="max-height:10px;" href="http://pf.kakao.com/_xhDjxeb"
                                       target="_blank"><u>이용문의</u></a>
                                 </p>
                              </form>
                           </div>
                        </div>
                        <div class="col-lg-5 content-right">
                           <img src="@/assets/images/login/01.png" class="img-fluid image-right" alt="">
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div>
            <div class="container">
               <div class="container border-top py-3">

                  <div class="row justify-content-between">

                     <div class="col-8 mb-1 mb-md-0 text-start" style="font-size:70%">
                        <div class="info">
                           (주)루비데이터랩 대표 조효원&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;경기도 김포시 김포대로
                           1009-3(북변동)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           개인정보관리 책임자 강철민&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           <!-- 사업자 등록번호 114-88-01873&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
                           rubydatalab@gmail.com&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                           전화번호 070-8064-1027&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           사업자 등록번호 733-81-02688&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           통신판매업 신고 2023-경기김포-0851
                        </div>
                        <p class="mb-0">Copyrights all reserves RUBYDATALAB Corporation </p>

                     </div>
                     <div class="col-4">
                        <p class="mb-0">
                           Made with<span class="fas fa-heart mx-1 text-danger"> </span>by COUPLUS</p>
                     </div>
                  </div>
               </div>
            </div><!-- end of .container-->
         </div>
      </div>
   </section>
</template>
<script>
import axios from 'axios'
// import { loadTossPayments } from '@tosspayments/payment-sdk'


// async/await을 사용하는 경우


export default {
   name: 'SignIn',
   data() {
      return {
         now:'',
         allchecked: false,
         check1: false,
         check2: false,
         check3: false,
         check4: false,
         clientKey: 'test_ck_XjExPeJWYVQwZyOo7ng849R5gvNL',
         tossPayments: '',
         companyid: "",
         email: "",
         pw: "",
         showspinner: false,
         selected: 'lite',
         customerKey: '',
         cardNumber: '',
         cardExpirationYear: '',
         cardExpirationMonth: '',
         customerIdentityNumber: '',
         // cardPassword: '',
         cardNumberError: '',
         expiryDateError: '',
         birthdateError: '',
         // cardPasswordError: '',
         classapproval: 'btn btn-outline-light mt-3 disabled',
         selectedradio: 'radio1',
         options: [
            { text: '기본약관', value: 'radio1' },
            { text: '개인정보 수집 이용 동의', value: 'radio2' },
            { text: '개인정보 제 3자 제공 동의', value: 'radio3' },
         ],
         showradio1: true,
         showradio2: false,
         showradio3: false,
         법인카드:false,
      }
   },
   methods: {
      checkcard(){
         this.customerIdentityNumber = ""
      },
      makenow(){
         var today = new Date();
         this.now = today.getDate();
      },
      validateCardNumber() {
         const regex = /^[0-9]{15,16}$/;
         if (!regex.test(this.cardNumber) && this.cardNumber) {
            this.cardNumberError = '카드번호를 올바르게 입력해주세요.';
         } else {
            this.cardNumberError = '';
         }
      },
      validateExpiryDate() {
         const yearRegex = /^\d{2}$/;
         const monthRegex = /^(0[1-9]|1[0-2])$/;
         if (this.cardExpirationMonth && this.cardExpirationYear) {
            if (!yearRegex.test(this.cardExpirationYear) || !monthRegex.test(this.cardExpirationMonth)) {
               this.expiryDateError = '카드 유효기간을 올바르게 입력해주세요.';
            } else {
               this.expiryDateError = '';
            }
         } else {
            this.expiryDateError = '';
         }

      },
      validatecustomernumber() {
         const regex1 = /^[0-9]{6}$/;
         const regex2 = /^[0-9]{10}$/;
         if(this.법인카드){
            if (!regex2.test(this.customerIdentityNumber) && this.customerIdentityNumber) {
               this.birthdateError = '사업자번호 10자리를 올바르게 입력해주세요.';
            } else {
               this.birthdateError = '';
            }
         } else {
            if (!regex1.test(this.customerIdentityNumber) && this.customerIdentityNumber) {
               this.birthdateError = '생년월일 6자리를 올바르게 입력해주세요.';
            } else {
               this.birthdateError = '';
            }
         }
      },
      // validateCardPassword() {
      //    const regex = /^\d{2}$/;
      //    if (!regex.test(this.cardPassword) && this.customerIdentityNumber) {
      //       this.cardPasswordError = '비밀번호 앞 두자리를 올바르게 입력해주세요.';
      //    } else {
      //       this.cardPasswordError = '';
      //    }
      // },
      async payment() {
         // alert('PG사 계약 및 카드사 심사 중으로 구독결제는 계약완료 후 ' + '\n' +
         // '활성화될 예정입니다. 바로 이용이 필요하신 경우 아래 계좌로 ' + '\n' +
         // '입금해주시면 순차적으로 승인처리하도록 하겠습니다. ' + '\n' +
         // '기타 문의사항은 아래 채널톡으로 남겨주세요' + '\n' +
         // 'https://center-pf.kakao.com/_xhDjxeb/chats' + '\n\n' +
         // '국민은행 850101-00-098874 (주)루비데이터랩' + '\n' +
         // '라이트버전:49,000원, 프리미엄버전:290,000원' + '\n' +
         // '*입금자명과 가입자명이 일치해야 빠르게 승인이 가능합니다.*')
         var now = Date.now();
         var order = 'COUPLUS_' + now + "_"
         axios.post('/api/login/payment', {

            email: this.email,
            data: {
               cardNumber: this.cardNumber,
               cardExpirationYear: this.cardExpirationYear,
               cardExpirationMonth: this.cardExpirationMonth,
               // cardPassword: this.cardPassword,
               customerIdentityNumber: this.customerIdentityNumber.toString().replaceAll("-",""),
               customerKey: this.customerKey,
               email: this.email,
               amount : this.selected == 'lite' ? '49000' : '290000',
               orderId : order,
               orderName : this.selected == 'lite' ? 'COUPLUS-Lite' : 'COUPLUS-Premium'
            }
         })
         .then(result => {
            if(result.data.status !== 200){
               alert(result.data.message);
               return
            }
            console.log(result.data)
            if(result.data.status == 200 && result.data.code == 'ok'){
               this.onSubmit();
            } else {
               console.log(result.data)
               alert("로그인 실패")
            }
            // if (result.data.loginstatus == "Login success") {

            //    this.$store.commit('userupdate', result.data.userinfo);
            //    this.$store.commit('dbupdate', result.data.dbs);
            //    // console.log("state반영 후 db")
            //    console.log(this.$store.state.user);
            //    // console.log(this.$store.state.polist);
            //    // console.log(this.$store.state.settings);
            //    this.showspinner = false;
            //    if(this.$store.state.user.auth.showlite){
            //       this.$router.push({ name: 'product.listproduct' })
            //    } else {
            //       this.$router.push({ name: 'product.listproduct' })
            //    }
            // } else {
            //    this.showspinner = false;
            //    if (result.data.loginstatus == '가입승인 대기중입니다. 관리자에게 문의해 주세요') {
            //       this.$bvModal.show('modal-reg');

            //    } else {
            //       alert(result.data.loginstatus);
            //    }

            //    this.$store.commit('userupdate', this.$store.state.userinit);
            //    this.$router.push({ name: 'auth.login' });
            // }
         }).catch((e) => {
            this.showspinner = false;
            alert("로그인 실패")
            console.log(e);
         })
      },
      contentchange() {
         if (this.selectedradio == 'radio1') {
            this.showradio1 = true;
            this.showradio2 = false;
            this.showradio3 = false;
         }
         if (this.selectedradio == 'radio2') {
            this.showradio1 = false;
            this.showradio2 = true;
            this.showradio3 = false;
         }
         if (this.selectedradio == 'radio3') {
            this.showradio1 = false;
            this.showradio2 = false;
            this.showradio3 = true;
         }
      },
      checkprivacy() {
         this.check2 = true;
         this.confirmcheck();
      },
      checkterms() {
         this.check3 = true;
         this.confirmcheck();
      },
      checkservice() {
         this.check4 = true;
         this.confirmcheck();
      },
      confirmerror() {
         if (!this.cardNumberError && !this.expiryDateError && !this.birthdateError && this.cardNumber && this.cardExpirationYear && this.cardExpirationMonth && this.customerIdentityNumber) {
            // console.log(this.cardNumber)
            return true
         } else {
            return false
         }
      },
      confirmcheck() {
         if (this.check1 && this.check2 && this.check3 && this.check4) {
            this.allchecked = true;
            if (this.confirmerror()) {
               this.classapproval = 'btn btn-success mt-3'
            } else {
               this.classapproval = 'btn btn-outline-light mt-3 disabled';
            }
         } else {
            this.allchecked = false;
            this.classapproval = 'btn btn-outline-light mt-3 disabled';
         }
      },
      allcheck() {
         if (this.allchecked) {
            this.check1 = true;
            this.check2 = true;
            this.check3 = true;
            this.check4 = true;
         } else {
            this.check1 = false;
            this.check2 = false;
            this.check3 = false;
            this.check4 = false;
         }
         this.confirmcheck()
      },
      onSubmit() {
         this.showspinner = true;
         axios.post('/api/login', {
            email: this.email,
            pw: this.pw,
         })
            .then(result => {
               // console.log(result.data)
               // console.log("state반영 전 db")
               // console.log(result.data.loginstatus)
               // console.log(result.data.dbs);
               if (result.data.loginstatus == "Login success") {

                  this.$store.commit('userupdate', result.data.userinfo);
                  this.$store.commit('dbupdate', result.data.dbs);
                  // console.log("state반영 후 db")
                  // console.log(this.$store.state.user);
                  // console.log(this.$store.state.polist);
                  // console.log(this.$store.state.settings);
                  this.showspinner = false;
                  if(this.$store.state.user.auth.showlite){
                     this.$router.push({name:'analysis.keyword'})
                  } else {
                     if(!this.$store.state.user.auth.showproduct){
                        this.$router.push({name: 'analysis.keywordoptimize'});  
                     } else {
                        this.$router.push({ name: 'product.listproduct' })
                     }
                  }


               } else {
                  this.showspinner = false;
                  if (result.data.loginstatus == '가입승인 대기중입니다. 관리자에게 문의해 주세요') {
                     this.customerKey = result.data.userinfo._id;
                     this.$bvModal.show('modal-reg');

                  } else {
                     alert(result.data.loginstatus);
                  }

                  this.$store.commit('userupdate', this.$store.state.userinit);
                  // this.$router.push({ name: 'auth.login' });
               }

            }).catch((e) => {
               this.showspinner = false;
               alert("로그인 실패")
               console.log(e);
            })
      },
      submitForm() {
         // 폼 제출 로직 추가
         console.log('카드 번호:', this.cardNumber);
         console.log('카드 유효기간:', this.expiryDate);
         console.log('카드 소유자 생년월일:', this.birthday);
         this.closeModal();
      }

   }
}
</script>

<style>.error-message {
   color: red;
}</style>
